import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [toastModal, setToastModal] = useAtom(modalAtom.toastModalAtom);
  const [termsModal, setTermsModal] = useAtom(modalAtom.termsModalAtom);
  const [articleModal, setArticleModal] = useAtom(modalAtom.articleModalAtom);
  const [imageModal, setImageModal] = useAtom(modalAtom.imageModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setToastModal({ show: false });
    setTermsModal({ show: false });
    setArticleModal({ show: false });
    setImageModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setDefaultModal((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    clearSubModals,
    defaultModal,
    setDefaultModal,
    toastModal,
    setToastModal,
    termsModal,
    setTermsModal,
    articleModal,
    setArticleModal,
    imageModal,
    setImageModal,
  };
}
