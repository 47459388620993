import { useBoard, usePopup } from "hooks";
import {
  MainBanner,
  MainExample,
  MainProcess,
  MainLawyerList,
  MainMonthly,
  MainReview,
  MainYoutube,
  MainBroadcast,
  MainSearch,
  MainCounsel,
  MainCenter,
  MainDirection,
} from "./components";

export default function MainPage() {
  const popupInfo = usePopup({
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "113",
      type: 10,
      recordSize: 10,
    },
  });

  return (
    <>
      <MainBanner />
      <MainSearch />
      <MainProcess />
      <MainLawyerList />
      <MainMonthly />
      <MainExample />
      <MainYoutube />
      <MainReview />
      <MainBroadcast />
      <MainCounsel />
      <MainCenter />
      <MainDirection />
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`#형사변호사 #형사전문변호사 #형사법무법인 #형사로펌 #형사전문법무법인 #형사전문로펌 #형사전문로펌 #형사사건 #형사사건변호사 #형사고소 #형사소송 #검사출신변호사 #법률상담 #변호사상담 #전관변호사 #변호사선임 #검사출신법무법인 #전관법무법인 #법무법인선임 #서울형사전문법무법인 #서울형사법무법인 #인천형사법무법인 #인천형사로펌 #인천형사전문법무법인 #인천형사전문로펌 #안산형사법무법인 #안산형사로펌 #안산형사전문법무법인 #안산형사전문로펌 #인천형사변호사 #인천형사전문변호사 #안산형사변호사 #안산형사전문변호사 #서울형사변호사 #서울형사전문변호사 #강남형사변호사 #강남형사전문변호사 #사기 #불법도박 #주식리딩방사기 #횡령 #배임 #특수폭행 #인천변호사 #안산변호사`
          .split(" ")
          .map((tag) => (
            <li
              key={tag}
              style={{
                backgroundColor: "white",
                color: "white",
                fontSize: "14px",
              }}
            >
              {tag}
            </li>
          ))}
      </ul>
    </>
  );
}
