import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  & .youtube_slide {
    width: 100%;
    & .tag {
      position: relative;
      width: 100%; height: 190px;
      & img {
        width: 100%; height: 100%;
        object-fit: cover;
      }
    }
    & .youtube_text {
      & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      }
      & .tit {
        font-size: 18px;
        line-height: 27px;
        font-weight: bold;
        margin-top: 20px;
      }
    }
  }

  & .slide_btn {
    position: absolute;
    top: 50%;
    width: 70px; height: 70px;
    transform: translateY(-50%);
    z-index: 1;
    &.btn_prev {
      left: 50%;
      margin-left: -750px;
    }
    &.btn_next {
      right: 50%;
      margin-right: -750px;
    }
  }
    

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .youtube_slide {
      & .tag {
        height: calc(100vw*(190/1520));
      }
      & .youtube_text {
        & .tit {
          font-size: calc(100vw*(24/1520));
          line-height: calc(100vw*(36/1520));
          margin-top: calc(100vw*(30/1520));
        }
      }
    }
    & .slide_btn {
      width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
      &.btn_prev {
        margin-left: calc(100vw*(-750/1520));
      }
      &.btn_next {
        margin-right: calc(100vw*(-750/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .youtube_slide {
      padding: 0 calc(100vw*(48/375));
      & .tag {
        height: calc(100vw*(152/375));
      }
      & .youtube_text {
        & .tit {
          font-size: calc(100vw*(16/375));
          line-height: calc(100vw*(27/375));
          margin-top: calc(100vw*(15/375));
          font-weight: 800;
          letter-spacing: -0.7px;
        }
      }
    }

    & .slide_btn {
      width: calc(100vw*(35/375)); height: calc(100vw*(35/375));
      &.btn_prev {
        margin-left: calc(100vw*(-180/375));
      }
      &.btn_next {
        margin-right: calc(100vw*(-180/375));
      }
    }
  }
`;




export {
  Container
}

export default {
  Container
}