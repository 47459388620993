import { SubBanner, SearchList } from "./components";

export default function SearchPage() {
  return (
    <>
      <SearchList/>
      <SubBanner/>
    </>
  );
}
