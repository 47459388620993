import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  & .wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 936px; height: 607px;
    padding: 40px 20px 60px;
    background: #fff;
    & .closeButton {
      display: flex;
      align-items: center;
      position: absolute;
      top: -39px; right: 0;
      font-size: 18px;
      color: #fff;
      font-weight: 600;
      & img {
        width: 24px; height: 24px;
        margin-left: 4px;
      }
    }
    & .title {
      font-size: 20px;
      font-weight: 800;
      text-align: left;
      border-bottom: 2px solid #111;
      padding-bottom: 14px;
      & + .info {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    & .inner {
      height: 350px;
      overflow-y: auto;
      padding-bottom: 10px;
      & .text {
        color: #111;
        line-height: 1.4;
        font-size: 16px;
      }
    }
    & .info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; min-height: 48px;
      padding: 12px 0;
      background-color: #F5F5F5;
      & span {
        display: block;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        font-weight: 500;
        color: #888;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(1, 1, 1, 0.7);
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .wrap {
      width: calc(100vw*(936/1520)); height: calc(100vw*(607/1520));
      padding: calc(100vw*(40/1520)) calc(100vw*(20/1520)) calc(100vw*(60/1520));
      & .closeButton {
        top: calc(100vw*(-39/1520));
        font-size: calc(100vw*(18/1520));
        & img {
          width: calc(100vw*(24/1520)); height: calc(100vw*(24/1520));
          margin-left: calc(100vw*(4/1520));
        }
      }
      & .title {
        padding-bottom: calc(100vw*(14/1520));
        font-size: calc(100vw*(20/1520));
        & + .info {
          margin-top: calc(100vw*(10/1520));
          margin-bottom: calc(100vw*(20/1520));
        }
      }
      & .inner {
        height: calc(100vw*(320/1520));
        & .text {
          font-size: calc(100vw*(16/1520));
        }
      }
      & .info {
        min-height: calc(100vw*(48/1520));
        padding: calc(100vw*(12/1520)) 0;
        margin-top: calc(100vw*(20/1520));
        & span {
          font-size: calc(100vw*(16/1520));
          line-height: calc(100vw*(19/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .wrap {
      width: calc(100vw*(290/375)); height: calc(100vw*(500/375));
      max-height: 80vh;
      padding: calc(100vw*(30/375)) calc(100vw*(15/375)) calc(100vw*(40/375));
      & .closeButton {
        top: calc(100vw*(-28/375));
        font-size: calc(100vw*(16/375));
        & img {
          width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
          margin-left: calc(100vw*(4/375));
        }
      }
      & .title {
        padding-bottom: calc(100vw*(14/375));
        font-size: calc(100vw*(18/375));
        letter-spacing: -0.5px;
        & + .info {
          margin-top: calc(100vw*(10/375));
          margin-bottom: calc(100vw*(20/375));
        }
      }
      & .inner {
        height: calc(100vw*(205/375));
        max-height: calc(80vh - calc(100vw*(300/375)));
        padding-bottom: calc(100vw*(10/375));
        & .text {
          font-size: calc(100vw*(14/375));
        }
      }
      & .info {
        min-height: calc(100vw*(48/375));
        padding: calc(100vw*(10/375)) 0;
        margin-top: calc(100vw*(20/375));
        & span {
          font-size: calc(100vw*(12/375));
          line-height: calc(100vw*(17/375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
