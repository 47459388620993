import styled, { css } from 'styled-components';

// img
import BnnrImg from "resources/image/main/sub_banner_img.png";
import MbBnnrImg from "resources/image/main/mb_sub_banner_img.jpg";
import QuotesImg from "resources/image/icon/quotes_img_wh.svg";

const SearchList = styled.div`
  position: relative;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 150px;
  & .search_box {
    width: 1260px;
    margin: 0 auto;
    & h2 {
      font-size: 36px;
      line-height: 43px;
      margin-bottom: 50px;
      text-align: center;
      & span {color: #DA250B; font-weight: 800;}
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding-top: calc(100vw*(160/1520));
    padding-bottom: calc(100vw*(150/1520));
    & .search_box {
      width: calc(100vw*(1260/1520));
      & h2 {
        font-size: calc(100vw*(36/1520));
        line-height: calc(100vw*(43/1520));
        margin-bottom: calc(100vw*(50/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding-top: calc(100vw*(120/375));
    padding-bottom: calc(100vw*(80/375));
    & .search_box {
      width: 100%;
      padding: 0 calc(100vw*(20/375));
      & h2 {
        font-size: calc(100vw*(26/375));
        line-height: calc(100vw*(31/375));
        margin-bottom: calc(100vw*(30/375));
        letter-spacing: -0.5px;
        white-space: nowrap;
      }
    }
  }
`;




export {
  SearchList
}

export default {
  SearchList
}