import { useState, useEffect, useRef } from "react";
import Styles from "./styles";
import { Checkbox, Radio, Input } from "components/ui/input";
import { useCategory, useInquiry, useModals } from "hooks";

// img
import IconErr from "resources/image/icon/icon_err.svg";

function Counsel() {
  const modalOption = useModals();
  const [tel, setTel] = useState("");
  const [type, setType] = useState(null);
  const [agree, setAgree] = useState(false);
  const [err, setErr] = useState(0);

  const categoryInfo = useCategory({
    listOption: {
      type: 8,
      parent_info_id: 7,
    },
  });

  const inquiryInfo = useInquiry();

  const errMessage = (type) => {
    switch (type) {
      case 1:
        return "사건 유형을 선택해 주세요.";
      case 2:
        return "연락처를 입력해 주세요.";
      case 3:
        return "개인정보 수집 및 이용에 동의해 주세요.";
      default:
        return;
    }
  };

  let isLoad = useRef(false);
  const doValidate = async () => {
    if (type == null) {
      setErr(1);
    } else if (tel.length == 0) {
      setErr(2);
    } else if (agree == false) {
      setErr(3);
    } else {
      if (isLoad.current) return;
      isLoad.current = true;
      let result = await inquiryInfo.doInquiry(type.id, tel);
      if (result.success == true) {
        setErr(0);
        setTel("");
        setAgree(null);
        setType(null);

        modalOption.setTermsModal((e) => {
          e.show = true;
          try{
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'popup_opened',
              modalName: 'termsModal'
            });
          }catch(e){
            console.log(e);
          }
          return { ...e };
        });
        setTimeout(() => {
          isLoad.current = true;
        }, 1000);
      } else {
        isLoad.current = false;
        alert("잠시 뒤 다시 시도해주세요");
      }
    }
  };

  return (
    <Styles.Counsel className="counsel_form">
      <div className="form_inner">
        <h3>상담신청</h3>
        <p>
          사건의 해결은 정확한 방향 설정부터 시작합니다.
          <br />
          상담부터 검사출신 변호사가 직접 진행합니다.
        </p>
        <ul className="input_wrap">
          <li className="radio_list">
            <p>사건 유형</p>
            <div className="radio_box">
              {categoryInfo?.list.map((value, index) => {
                return (
                  <Radio
                    key={"categoryInfoList" + index}
                    labelText={value.title}
                    id={"type_counsel_0" + index}
                    name="type"
                    checked={type?.id === value.id}
                    onChange={(e) => {
                      setType(value);
                    }}
                  />
                );
              })}
            </div>
          </li>
          <li className="text_list">
            <p>연락처</p>
            <div className="input_box">
              <Input
                placeholder="숫자만 입력해 주세요."
                value={tel}
                setValue={setTel}
                onChange={(e) => {
                  let text = e.target.value;
                  text = text.replace(/[^0-9]/gi, "");
                  text = text.replace(/-/gi, "");
                  text =
                    text?.substr(0, 3) +
                    (text?.length > 3
                      ? "-" +
                        text?.substr(3, 4) +
                        (text?.length > 7 ? "-" + text?.substr(7, 4) : "")
                      : "");
                  setTel(text);
                }}
              />
            </div>
            <div className="check_box">
              <Checkbox
                labelText="개인정보 수집 및 이용에 동의합니다."
                id={"agree"}
                checked={agree}
                onChange={(e) => {
                  setAgree(e.target.checked);
                }}
              />
              <span
                onClick={() => {
                  modalOption.setDefaultModal((e) => {
                    e.show = true;
                    e.title = "개인정보보호를 위한 이용자 동의 사항";
                    e.dec =
                      '1. 개인정보의 수집 및 이용 목적 : 문의에 대한 답변<br/>2. 수집항목 : 연락처, 이메일<br/>3. 개인정보의 보유 및 이용기간 : 이용목적이 <br class="mbBr"/>달성된 때까지 <br class="pcBr"/>보유합니다.';
                    return { ...e };
                  });
                }}
              >
                전문보기
              </span>
            </div>
          </li>
        </ul>
        <div className="btn_box">
          {err ? (
            <span className="err">
              <img src={IconErr} alt="error" />
              {errMessage(err)}
            </span>
          ) : null}
          <button className="btn" onClick={doValidate}>
            비밀 상담 신청하기
          </button>
          <span className="aside_dec">
            <a href="tel:+1670-5901">1670-5901</a> 으로 문의사항을 남겨주시면
            <br />
            즉시 도와드리겠습니다.
          </span>
        </div>
      </div>
    </Styles.Counsel>
  );
}

export { Counsel };

export default Counsel;
