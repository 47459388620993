import { System } from "components/layout";
import Styles from './styles';


function SystemLayout() {

  return (
    <Styles.SystemLayout>
      <div className="system_title">
        <p className="tit">나의 상황,<br/>
          어디에 털어놓지 못해<br/>
          <strong>답답하시죠?</strong>
        </p>
        <p className="text">성범죄 사건 해결의 시작은 <br className="mbBr"/><span>정확한 죄명 진단</span>부터 시작합니다.</p>
        <p className="dec">
          성범죄 사건 수사 · 재판하던 검사들이<br/>
          직접 개발한 죄명 검색 솔루션!
        </p>
        <span className="sub">의뢰인의 개인정보 및 상담 내용은 비밀리에 관리됩니다.</span>
      </div>
      <System />
    </Styles.SystemLayout>
  );
}

export { SystemLayout };

export default SystemLayout;
