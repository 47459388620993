import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vw * (100 / 1920));
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 90;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .tel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(100vw * (17 / 1920));
    flex-shrink: 0;
    width: calc(100vw * (301 / 1920));
    padding-right: calc(100vw * (28 / 1920));
    background-color: #2f2f2f;

    .icon {
      width: calc(100vw * (44 / 1920));
      height: calc(100vw * (44 / 1920));
    }

    .textWrap div:first-child {
      margin-bottom: calc(100vw * (2 / 1920));
      font-size: calc(100vw * (32 / 1920));
      font-weight: 600;
    }

    .textWrap div:last-child {
      font-size: calc(100vw * (14 / 1920));
      font-weight: 500;
      color: #888;
    }
  }

  .quickBarWrap {
    flex: 1 0;
    display: flex;
    align-items: center;
    gap: calc(100vw * (30 / 1920));
    padding: 0 calc(100vw * (30 / 1920));
    background-color: rgba(0, 0, 0, 0.9);

    .kakaoLink {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(100vw * (8 / 1920));
      width: calc(100vw * (190 / 1920));
      height: calc(100vw * (47 / 1920));
      font-size: calc(100vw * (20 / 1920));
      font-weight: bold;
      color: #2f2f2f;
      background-color: #ffc800;
      border-radius: calc(100vw * (10 / 1920));

      .icon {
        width: calc(100vw * (32 / 1920));
        height: calc(100vw * (32 / 1920));
      }
    }

    .selectWrap,
    .inputWrap {
      display: flex;
      align-items: center;
      gap: calc(100vw * (10 / 1920));

      label {
        font-size: calc(100vw * (16 / 1920));
        font-weight: 600;
      }
    }

    .select {
      position: relative;

      .nowSelect {
        position: relative;
        display: flex;
        align-items: center;
        width: calc(100vw * (270 / 1920));
        height: calc(100vw * (54 / 1920));
        padding: 0 calc(100vw * (16 / 1920));
        font-size: calc(100vw * (16 / 1920));
        font-weight: 500;
        color: #c4c4c4;
        background-color: #fff;
        border: 1px solid #c4c4c4;
        border-radius: calc(100vw * (10 / 1920));
        cursor: pointer;

        &::after {
          position: absolute;
          top: 50%;
          right: calc(100vw * (16 / 1920));
          transform: translateY(-50%);
          width: calc(100vw * (20 / 1920));
          height: calc(100vw * (20 / 1920));
          background: url(${IconArrowBottom}) no-repeat center / cover;
          transition: 0.3s;
          content: "";
        }

        &.on {
          color: #111;
        }

        &.open::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      ul {
        position: absolute;
        bottom: calc(100% + calc(100vw * (5 / 1920)));
        left: 0;
        width: 100%;
        border: 1px solid #c4c4c4;

        li {
          display: flex;
          align-items: center;
          height: calc(100vw * (36 / 1920));
          padding: 0 calc(100vw * (16 / 1920));
          font-size: calc(100vw * (14 / 1920));
          font-weight: 500;
          color: #888888;
          background-color: #fff;
          cursor: pointer;
        }
      }
    }

    .inputWrap input {
      width: calc(100vw * (270 / 1920));
      height: calc(100vw * (54 / 1920));
      padding: 0 calc(100vw * (16 / 1920));
      font-size: calc(100vw * (16 / 1920));
      border-radius: calc(100vw * (10 / 1920));
      background-color: #fff;
    }

    .checkboxWrap {
      .checkboxText {
        font-size: calc(100vw * (14 / 1920));
      }

      button {
        margin-left: 5px;
        font-size: calc(100vw * (14 / 1920));
        font-weight: 800;
        color: #fff;
        text-decoration: underline;
        text-underline-offset: calc(100vw * (3 / 1920));
      }
    }

    .submitButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw * (200 / 1920));
      height: calc(100vw * (47 / 1920));
      margin-left: calc(100vw * (20 / 1920));
      font-size: calc(100vw * (18 / 1920));
      font-weight: 700;
      color: #fff;
      background-color: #da250b;
      border-radius: calc(100vw * (10 / 1920));
    }
  }
`;

export { Container };

export default {
  Container,
};
