import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";
import { PC, Mobile } from "hooks/useMediaQuery";
import { NavLink } from "react-router-dom";

// img
import IconNext from "resources/image/icon/icon_next.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import ImgBox from "../ImgBox";

const BASE_VIEW = 3;
function CategorySwiperWrap({
  categoryTitle,
  list,
  perView,
  perGroup,
  callback,
}) {
  const [swiper, setSwiper] = useState(null);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  return (
    <Styles.CategorySwiperWrap>
      <p className="category-title">{categoryTitle}</p>

      <div className="sw-container youtube">
        <PC>
          <div className="swiper_wrap">
            <Swiper
              className="slider"
              slidesPerView={perView ? perView : BASE_VIEW}
              loop={true}
              speed={600}
              spaceBetween={30}
              slidesPerGroup={perGroup ? perGroup : BASE_VIEW}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"row-swiper" + index}>
                    <a
                      onClick={() => {
                        callback(item);
                      }}
                    >
                      <div className="sw-story">
                        <ImgBox
                          src={`https://img.youtube.com/vi/${item?.subtitle}/mqdefault.jpg`}
                        />
                        <p className="title">{item.title}</p>
                        {/* <span className="sub-title">{item.subtitle}</span> */}
                      </div>
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </PC>
        <Mobile>
          <div className="swiper_wrap">
            <Swiper
              className="slider"
              slidesPerView={1}
              loop={true}
              speed={600}
              spaceBetween={20}
              slidesPerGroup={1}
              onSwiper={(e) => {
                setSwiper(e);
              }}
            >
              {list?.map((item, index) => {
                return (
                  <SwiperSlide key={"row-swiper" + index}>
                    <a
                      onClick={() => {
                        callback(item);
                      }}
                    >
                      <div className="sw-story">
                        <ImgBox
                          src={`https://img.youtube.com/vi/${item?.subtitle}/mqdefault.jpg`}
                        />
                        <p className="title">{item.title}</p>
                        <span className="sub-title">{item.subTitle}</span>
                      </div>
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </Mobile>
        <button className="slide_btn btn_prev" onClick={handlePrev}>
          <img src={IconPrev} alt="슬라이드 이전" />
        </button>
        <button className="slide_btn btn_next" onClick={handleNext}>
          <img src={IconNext} alt="슬라이드 다음" />
        </button>
      </div>
    </Styles.CategorySwiperWrap>
  );
}

export { CategorySwiperWrap };

export default CategorySwiperWrap;
