import styled, { css } from "styled-components";

const System = styled.div`
  position: relative;
  width: 575px; min-height: 595px;
  padding-bottom: 65px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 10px 10px 50px 0 rgba(83, 83, 83, 0.20);
  border: 1px solid #C4C4C4;
  overflow: hidden;
  & .form_inner {
    & .badge {
      position: absolute;
      left: 28px; top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 78px; height: 86px;
      background-color: #111;
      font-size: 26px;
      line-height: 31px;
      font-weight: 600;
      padding-top: 6px;
      text-decoration: underline;
      color: #fff;
      border-bottom: 5px solid #DA250B;
      & + h3 {padding-left: 127px;}
    }
    & h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%; height: 60px;
      font-size: 20px;
      font-weight: bold;
      background-color: #F5F5F5;
      padding: 0 30px;
    }
    & .btn {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      color: #888;
      text-decoration: underline;
    }
    & .radio_box {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 56px 28px 0;
    }
    & .form_box {
      padding: 18px 29px 0;
      & .provisions_list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        & .row {
          padding: 26px 16px;
          border: 1px solid #C4C4C4;
          border-radius: 5px;
          & .sub {
            display: block;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #888;
            letter-spacing: -0.3px;
          }
          & .tit {
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
            color: #DA250B;
            margin-top: 8px;
            margin-bottom: 6px;
            letter-spacing: -0.3px;
          }
        }
      }
      & .form_input {
        margin-top: 15px;
        padding-bottom: 40px;
        &:first-child {margin-top: 0;}
        & .info_text {
          font-size: 14px;
          line-height: 20px;
          color: #888;
        }
        & .input_wrap {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          margin-top: 15px;
          & li {
            position: relative;
            width: calc(50% - 5px);
            & p {
              position: relative;
              font-size: 18px;
              line-height: 21px;
              font-weight: 600;
              padding-left: 12px;
              margin-top: 0;
              &::before {
                content: "";
                position: absolute;
                left: 2px;
                top: 50%;
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                transform: translateY(-50%);
                background-color: #da250b;
              }
            }
            & .input_box {
              margin-top: 12px;
            }
          }
        }
      }
      & + .err {bottom: 55px;}
    }
    & .next_btn {
      position: absolute;
      right: 30px; bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 168px; height: 45px;
      font-size: 18px;
      font-weight: bold;
      border: 1px solid #111;
      border-radius: 45px;
      padding: 0 30px;
      & img {
        width: 18px; height: 18px;
        object-fit: contain;
      }
    }
    & .submit_btn {
      position: absolute;
      left: 0; bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; height: 50px;
      font-size: 18px;
      font-weight: 600;
      background-color: #DA250B;
      color: #fff;
    }
    & .err {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      position: absolute;
      left: 0; bottom: 33px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold;
      color: #DA250B;
      & img {width: 18px; height: 18px;}
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw*(575/1520)); min-height: calc(100vw*(595/1520));
    padding-bottom: calc(100vw*(65/1520));
    border-radius: calc(100vw*(5/1520));
    box-shadow: calc(100vw*(10/1520)) calc(100vw*(10/1520)) calc(100vw*(50/1520)) 0 rgba(83, 83, 83, 0.20);
    & .form_inner {
      & .badge {
        left: calc(100vw*(28/1520));
        width: calc(100vw*(78/1520)); height: calc(100vw*(86/1520));
        font-size: calc(100vw*(26/1520));
        line-height: calc(100vw*(31/1520));
        padding-top: calc(100vw*(6/1520));
        & + h3 {padding-left: calc(100vw*(127/1520));}
      }
      & h3 {
        height: calc(100vw*(60/1520));
        font-size: calc(100vw*(20/1520));
        padding: 0 calc(100vw*(30/1520));
      }
      & .btn {
        font-size: calc(100vw*(14/1520));
        line-height: calc(100vw*(17/1520));
      }
      & .radio_box {
        gap: calc(100vw*(15/1520));
        padding: calc(100vw*(56/1520)) calc(100vw*(28/1520)) 0;
      }
      & .form_box {
        padding: calc(100vw*(18/1520)) calc(100vw*(29/1520)) 0;
        & .provisions_list {
          gap: calc(100vw*(15/1520));
          & .row {
            padding: calc(100vw*(26/1520)) calc(100vw*(16/1520));
            border-radius: calc(100vw*(5/1520));
            & .sub {
              font-size: calc(100vw*(14/1520));
              line-height: calc(100vw*(20/1520));
            }
            & .tit {
              font-size: calc(100vw*(20/1520));
              line-height: calc(100vw*(24/1520));
              margin-top: calc(100vw*(8/1520));
              margin-bottom: calc(100vw*(6/1520));
            }
          }
        }
        & .form_input {
          margin-top: calc(100vw*(15/1520));
          padding-bottom: calc(100vw*(40/1520));
          & .info_text {
            font-size: calc(100vw*(14/1520));
            line-height: calc(100vw*(20/1520));
          }
          & .input_wrap {
            gap: calc(100vw*(10/1520));
            width: 100%;
            margin-top: calc(100vw*(15/1520));
            & li {
              width: calc(50% - calc(100vw*(5/1520)));
              & p {
                font-size: calc(100vw*(18/1520));
                line-height: calc(100vw*(21/1520));
                padding-left: calc(100vw*(12/1520));
                &::before {
                  left: calc(100vw*(2/1520));
                  width: calc(100vw*(4/1520)); height: calc(100vw*(4/1520));
                }
              }
              & .input_box {
                margin-top: calc(100vw*(12/1520));
              }
            }
          }
        }
        & + .err {bottom: calc(100vw*(55/1520));}
      }
      & .next_btn {
        right: calc(100vw*(30/1520)); bottom: calc(100vw*(20/1520));
        width: calc(100vw*(168/1520)); height: calc(100vw*(45/1520));
        font-size: calc(100vw*(18/1520));
        border-radius: calc(100vw*(45/1520));
        padding: 0 calc(100vw*(30/1520));
        & img {
          width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));
        }
      }
      & .submit_btn {
        height: calc(100vw*(50/1520));
        font-size: calc(100vw*(18/1520));
      }
      & .err {
        gap: calc(100vw*(5/1520));
        bottom: calc(100vw*(33/1520));
        font-size: calc(100vw*(16/1520));
        line-height: calc(100vw*(19/1520));
        & img {width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));}
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: 100%; min-height: calc(100vw*(521/375));
    padding-bottom: calc(100vw*(70/375));
    border-radius: calc(100vw*(5/375));
    box-shadow: calc(100vw*(10/375)) calc(100vw*(10/375)) calc(100vw*(50/375)) 0 rgba(83, 83, 83, 0.20);
    & .form_inner {
      & .badge {
        left: calc(100vw*(20/375));
        width: calc(100vw*(59/375)); height: calc(100vw*(65/375));
        font-size: calc(100vw*(20/375));
        line-height: calc(100vw*(24/375));
        padding-top: calc(100vw*(6/375));
        & + h3 {padding-left: calc(100vw*(88/375));}
      }
      & h3 {
        height: calc(100vw*(50/375));
        font-size: calc(100vw*(16/375));
        padding: 0 calc(100vw*(20/375));
      }
      & .btn {
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(17/375));
      }
      & .radio_box {
        gap: calc(100vw*(10/375));
        padding: calc(100vw*(35/375)) calc(100vw*(20/375)) 0;
      }
      & .form_box {
        padding: calc(100vw*(14/375)) calc(100vw*(16/375)) 0;
        & .provisions_list {
          gap: calc(100vw*(15/375));
          & .row {
            padding: calc(100vw*(17/375)) calc(100vw*(12/375));
            border-radius: calc(100vw*(5/375));
            & .sub {
              font-size: calc(100vw*(14/375));
              line-height: calc(100vw*(20/375));
            }
            & .tit {
              font-size: calc(100vw*(18/375));
              line-height: calc(100vw*(21/375));
              margin-top: calc(100vw*(8/375));
              margin-bottom: calc(100vw*(6/375));
            }
          }
        }
        & .form_input {
          margin-top: calc(100vw*(15/375));
          padding-bottom: calc(100vw*(20/375));
          & .info_text {
            font-size: calc(100vw*(14/375));
            line-height: calc(100vw*(20/375));
            word-break: keep-all;
          }
          & .input_wrap {
            flex-direction: column;
            gap: calc(100vw*(15/375));
            width: 100%;
            margin-top: calc(100vw*(15/375));
            & li {
              width: 100%;
              & p {
                font-size: calc(100vw*(16/375));
                line-height: calc(100vw*(19/375));
                padding-left: calc(100vw*(12/375));
                &::before {
                  left: calc(100vw*(2/375));
                  width: calc(100vw*(4/375)); height: calc(100vw*(4/375));
                }
              }
              & .input_box {
                margin-top: calc(100vw*(12/375));
              }
            }
          }
        }
        & + .err {bottom: calc(100vw*(53/375));}
      }
      & .next_btn {
        right: 50%; bottom: calc(100vw*(30/375));
        width: calc(100vw*(132/375)); height: calc(100vw*(39/375));
        font-size: calc(100vw*(16/375));
        border-radius: calc(100vw*(45/375));
        padding: 0 calc(100vw*(20/375));
        transform: translateX(50%);
        & img {
          width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
        }
      }
      & .submit_btn {
        height: calc(100vw*(50/375));
        font-size: calc(100vw*(16/375));
      }
      & .err {
        gap: calc(100vw*(5/375));
        bottom: calc(100vw*(72/375));
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(17/375));
        & img {width: calc(100vw*(18/375)); height: calc(100vw*(18/375));}
      }
    }
  }
`;

export { System };

export default {
  System,
};
