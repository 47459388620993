import Footer from "layout/Footer";
import Header from "layout/Header";
import MbHeader from "layout/MbHeader";
import TopBanner from "layout/TopBanner";
import BottomQuickBar from "layout/BottomQuickBar";
import MbBottomQuickBar from "layout/MbBottomQuickBar";
import React from "react";
import { Outlet } from "react-router-dom";
import { PC, Mobile } from "hooks/useMediaQuery";
import { useState, useEffect, useRef } from "react";

export default function Container() {
  const ref = useRef();
  const [scroll, setScroll] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.addEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY > ref.current?.offsetHeight);

    const scrollY = window.scrollY; // 현재 스크롤 위치
    const innerHeight = window.innerHeight; // 화면에 보이는 영역 높이
    const scrollHeight = document.documentElement.scrollHeight; // 전체 문서 높이

    if (scrollY + innerHeight >= scrollHeight - 400) {
      setIsBottom(true); // 최하단 도달
    } else {
      setIsBottom(false); // 최하단이 아님
    }
  };

  return (
    <div className="container">
      <div className="container-inner">
        <TopBanner ref={ref} scroll={scroll} />
        <PC>
          <Header bannerScroll={scroll} />
        </PC>
        <Mobile>
          <MbHeader bannerScroll={scroll} />
        </Mobile>
        <Outlet />
        <PC>
          <BottomQuickBar scroll={scroll} />
        </PC>
        <Mobile>
          {
            isBottom == false && <MbBottomQuickBar scroll={scroll} />
          }
        </Mobile>
        <Footer scroll={scroll}  isBottom={isBottom} />
      </div>
    </div>
  );
}
