import Styles from "./styles";

// img
import IconSearch from "resources/image/icon/icon_search.svg";
import IconSearchWh from "resources/image/icon/icon_search_wh.svg";

function InputSearch({
  placeholder,
  value,
  onChange,
  onClick,
  onKeyUp,
  black,
  tag
}) {
  return (
    <Styles.InputSearch>
      <div className="input_box">
        <input
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoComplete="off"
          onKeyUp={(e)=>{
            if (e.key == "Enter") {
              onKeyUp && onKeyUp();
            }
          }}
          className={black && 'bk'}/>
        <button onClick={onClick}><img src={black ? IconSearchWh : IconSearch} alt="검색" /></button>
      </div>
      {
        tag &&
        <ul className="tag_list">
          <li onClick={()=>{ onKeyUp('경찰조사')}}>#경찰조사</li>
          <li onClick={()=>{ onKeyUp('합의금')}}>#합의금</li>
          <li onClick={()=>{ onKeyUp('스토킹처벌법')}}>#스토킹처벌법</li>
          <li onClick={()=>{ onKeyUp('보완수사')}}>#보완수사</li>
        </ul>
      }
    </Styles.InputSearch>
  );
}

export { InputSearch };

export default InputSearch;
