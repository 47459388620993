import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ExampleSlide } from "components/layout";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDeviceType } from "hooks/useMediaQuery";
import useSite from "hooks/useSite";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import IconNext from "resources/image/icon/icon_next.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import CertImg00 from "resources/image/main/cert_img_00.png";
import CertImg01 from "resources/image/main/cert_img_01.png";
import CertImg02 from "resources/image/main/cert_img_02.png";
import CertImg03 from "resources/image/main/cert_img_03.png";
import CertImg04 from "resources/image/main/cert_img_04.png";
import CertImg05 from "resources/image/main/cert_img_05.png";
import CertImg06 from "resources/image/main/cert_img_06.png";
import CertImg07 from "resources/image/main/cert_img_07.png";
import CertImg08 from "resources/image/main/cert_img_08.png";
import { useLocation } from "react-router-dom";
import utils from "utils";
import { useBoard } from "hooks";

function MainExample() {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const [swiper, setSwiper] = useState(null);
  const certList = [
    CertImg00,
    CertImg01,
    CertImg02,
    CertImg03,
    CertImg04,
    CertImg05,
    CertImg06,
    CertImg07,
    CertImg08,
  ];

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      if (swiper) {
        swiper.update(); // Swiper 수동 업데이트
      }
    }, 100);
    window.addEventListener("resize", handleResize);
  }, []);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      board_type: 11,
      parent_info_id: param.category_info_id ? param.category_info_id : "",
      type: 1,
      content_type: 2,
    };
    setFilterInfo({ ...f });
  }, [location]);

  const siteInfo = useSite({
    listCategoryOption: filterInfo,
  });
  const list = siteInfo.listCategoryQuery?.data?.list || [];

  return (
    <Styles.MainExample>
      <div className="slide_box">
        <h2>
          <strong>
            형사사건의 전문성, <br className="mbBr" />
            공공기관
          </strong>
          이 먼저 <br className="mbBr" />
          인정했습니다.
        </h2>
        <p>
          검사 재직시절, 오로지 <span>국가</span>와 <span>국민</span>만{" "}
          <br className="mbBr" />
          바라보며 일했습니다.
        </p>
        <span>
          의뢰인만 바라보며 변호하는 지금도, 다양한 공공기관으로부터{" "}
          <br className="mbBr" />
          전문성을 인정받았습니다.
          <br className="pcBr" />
          <br className="mbBr" />
          변호사로서도, 국가와 국민을 위한 봉사의 뜻을 <br className="mbBr" />
          변함없이 실천하겠습니다.
        </span>
        <div className="slide_list">
          <Swiper
            className="slider"
            slidesPerView={deviceType === "pc" ? 5.4 : 3.1}
            loop={true}
            loopFillGroupWithBlank={true}
            speed={600}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            spaceBetween={deviceType === "pc" ? 30 : 20}
            centeredSlides={true}
            onSwiper={(e) => {
              setSwiper(e);
            }}
          >
            {certList.map((item, index) => {
              return (
                <SwiperSlide key={"certificateList" + index}>
                  <div className="cer_slide">
                    <img src={item} alt="certificate" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <button className="slide_btn btn_prev" onClick={handlePrev}>
            <img src={IconPrev} alt="슬라이드 이전" />
          </button>
          <button className="slide_btn btn_next" onClick={handleNext}>
            <img src={IconNext} alt="슬라이드 다음" />
          </button>
        </div>
      </div>
      <div className="example_box">
        <h2>주요 성공사례</h2>
        <p>
          법무법인 온강을 믿고 맡겨주신 <br className="mbBr" />
          의뢰인의 <span>실제 성공사례</span>로 확인하세요.
        </p>
        <ExampleSlide list={list} tab={true} />
        <button
          className="btn_more"
          onClick={() => {
            navigate("/example");
          }}
        >
          더보기
          <img src={IconPlus} alt="more view" />
        </button>
      </div>
    </Styles.MainExample>
  );
}

export default MainExample;
