import styled, { css } from 'styled-components';

const sizes = {
  large: {
    height: '10rem',
    fontSize: '1.25rem'
  },
  medium: {
    height: '2.25rem',
    fontSize: '1rem'
  },
  small: {
    height: '1.75rem',
    fontSize: '0.875rem'
  }
};

//변수를 size 형태로 받아서 분기 처리가 가능합니다.
const sizeStyles = css`
  ${({ size }) => css`
    height: ${sizes[size] ? sizes[size].height : '1.75rem'};
    font-size: ${sizes[size] ? sizes[size].fontSize : '1rem'};
  `}
`;


const Button = styled.button`
  background: black;
  color: white;
  border-radius: 7px;
  padding: 20px;
  margin: 10px;
  font-size: 16px;
  :disabled {
    opacity: 0.4;
  }
  :hover {
    box-shadow: 0 0 10px yellow;
  }

  /* 크기 */
  ${sizeStyles}
`;


export {
  Button
}


export default {
  Button
};