import { Counsel } from "components/layout";
import Styles from './styles';


function MainCounsel() {

  return (
    <Styles.MainCounsel>
      <div className="counsel_inner">
        <div className="counsel_title">
          <h2 className="tit">의뢰인께서 <br className="pcBr"/>포기하지 않는 한 <br/><strong>온강도 <span>포기하지 않습니다.</span></strong></h2>
          <p className="dec">
            막막한 형사 사건에서 명쾌한 해법을 찾는 길,<br className="mbBr"/> 온강에 있습니다.<br/><strong> 단 10분</strong>, 불안감에서 해방시켜 드리겠습니다
            {/* 변호사법 제26조(비밀유지의무 등)<br/>
            변호사 또는 변호사이었던 자는 그 직무상 알게 된 비밀을<br/>
            누설하여서는 아니 된다. */}
          </p>
        </div>
        <Counsel />
      </div>
    </Styles.MainCounsel>
  );
}

export default MainCounsel;