import styled, { css } from 'styled-components';

// img
import CenterBg from "resources/image/main/center_bg.jpg";
import MbCenterBg from "resources/image/main/mb_center_bg.jpg";
import CenterBg01 from "resources/image/main/center_bg_01.jpg";
import CenterBg02 from "resources/image/main/center_bg_02.jpg";
import CenterBg03 from "resources/image/main/center_bg_03.jpg";
import CenterBg04 from "resources/image/main/center_bg_04.jpg";
import CenterBg05 from "resources/image/main/center_bg_05.jpg";
import CenterBg06 from "resources/image/main/center_bg_06.jpg";

const MainCenter = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: #000;
  overflow: hidden;
  & .center_inner {
    width: 1260px;
    margin: 0 auto;
    & .tit {
      font-size: 56px;
      line-height: 73px;
      font-weight: 400;
      letter-spacing: -0.5px;
      margin-bottom: 50px;
      text-align: center;
      word-wrap: break-word;
      word-break: keep-all;
      color: #fff;
      & strong {
        font-weight: 800;
      }
    }
    & .center_list {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%; height: 100%;
      background: url(${CenterBg}) no-repeat center /cover;
      transition: 0.6s;
      &.hover0 {background-image: url(${CenterBg01});}
      &.hover1 {background-image: url(${CenterBg02});}
      &.hover2 {background-image: url(${CenterBg03});}
      &.hover3 {background-image: url(${CenterBg04});}
      &.hover4 {background-image: url(${CenterBg05});}
      &.hover5 {background-image: url(${CenterBg06});}
      & li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3); height: 309px;
        font-size: 20px;
        color: #fff;
        border-right: 1px solid #888;
        cursor: pointer;
        &:hover {
          background-color: rgba(218,37,11,0.8);
          font-weight: 800;
        }
        &:nth-child(3n) {
          border-right: none;
        }
        &:nth-child(-n+3) {
          border-bottom: 1px solid #888;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(100/1520)) 0;
    background-size: calc(100vw*(1920/1520));
    & .center_inner {
      width: calc(100vw*(1260/1520));
      & .tit {
        font-size: calc(100vw*(56/1520));
        line-height: calc(100vw*(73/1520));
        margin-bottom: calc(100vw*(60/1520));
      }
      & .center_list {
        & li {
          height: calc(100vw*(309/1520));
          font-size: calc(100vw*(20/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(50/375)) 0;
    background-size: cover;
    & .center_inner {
      display: block;
      width: 100%;
      padding: 0 calc(100vw*(20/375));
      & .tit {
        text-align: center;
        font-size: calc(100vw*(36/375));
        line-height: calc(100vw*(47/375));
        margin-bottom: calc(100vw*(30/375));
        & strong {display: block;}
      }
      & .center_list {
        background-image: url(${MbCenterBg});
        background-size: calc(100vw*(375/375));
        & li {
          width: 50%;
          height: calc(100vw*(150/375));
          font-size: calc(100vw*(16/375));
          border-right: 1px solid #888;
          &:nth-child(3n) {
            border-right: 1px solid #888;
          }
          &:nth-child(2n) {
            border-right: none;
          }
          &:nth-child(-n+4) {
            border-bottom: 1px solid #888;
          }
        }
      }
    }
  }
`;




export {
  MainCenter
}

export default {
  MainCenter
}