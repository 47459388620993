import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  ${(e)=>{
    if(e.backgroundColor ){
      return `
        background: ${e.backgroundColor};
        ${
          e.backgroundColor != 'rgba(0,0,0,0)' ? 
          `
            width: 100vw;
            height: 100vh;
          `
          :
          ''
        }
      `
    }
  }}

  .wrap {
    position: relative;
    width: 740px;
    ${(e)=>{ if(e.width){ return `width: ${e.width}px;` } }}

    max-height: 469px;
    background-color: #fff;
    cursor: pointer;

    &.on {
      position: absolute;
    }

    .closeButton {
      background-color:grey;
      
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      z-index: 1;
    }

  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    .wrap {
      width: calc(100vw * (740 / 1520));
      max-height: calc(100vw * (769 / 1520));

      .closeButton {
        top: calc(100vw * (10 / 1520));
        right: calc(100vw * (10 / 1520));
        width: calc(100vw * (20 / 1520));
        max-height: calc(100vw * (20 / 1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    align-items: flex-start;
    top:100px;
    width: 100vw;
    height: 100vh;
    
    .wrap {
      top: 0 !important;
      left: 0 !important;
      width: calc(100vw * (335 / 375));
      max-height: calc(100vw * (347 / 375));

      &.on {
        position: relative;
      }

      .closeButton {
        top: calc(100vw * (10 / 375));
        right: calc(100vw * (10 / 375));
        width: calc(100vw * (20 / 375));
        max-height: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
