import Styles from "./styles";

function Input({
  placeholder,
  value,
  onChange,
}) {
  return (
    <Styles.Input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete="off"/>
  );
}

export { Input };

export default Input;
