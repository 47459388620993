import { useState } from "react";
import { InputSearch } from "components/ui/input";
import Styles from './styles';
import { useNavigate } from "react-router-dom";
import utils from "utils";


function MainSubBanner() {
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  return (
    <Styles.MainSubBanner>
      <div className="sub_inner">
        <div className='text_wrap'>
          <h2 className='tit'>여러분이 궁금한 <br className="mbBr"/><strong>형사사건의</strong> <span>‘모든 것’</span></h2>
          <p className='dec'>지금 바로 검색해 보세요.</p>
          <div className='search_box'>
            <InputSearch
              placeholder="사건분야, 변호사, 법률정보 등을 검색해 보세요."
              value={search}
              setValue={setSearch}
              black={true}
              tag={true}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyUp={(keyword)=>{
                navigate("/search" + utils.etc.getQueryParamUrl({ keyword : keyword ? keyword : search, }, true));
              }}
              onClick={()=>{
                navigate("/search" + utils.etc.getQueryParamUrl({ keyword : search, }, true));
              }}
            />
          </div>
        </div>
      </div>
    </Styles.MainSubBanner>
  );
}

export default MainSubBanner;