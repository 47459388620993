import { useState, useEffect } from "react";
import { useDeviceType } from "hooks/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Styles from './styles';

// img
import BroadcastImg from "resources/image/main/broadcast_img.jpg";
import IconNext from "resources/image/slide/icon_next_wh.svg";
import IconPrev from "resources/image/slide/icon_prev_wh.svg";
import useSite from "hooks/useSite";
import { IMAGEPATH } from "service/connector";
import { NavLink } from "react-router-dom";

function MainBroadcast() {
  const deviceType = useDeviceType();
  SwiperCore.use([Autoplay]);
  const [swiper, setSwiper] = useState(null);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };


  const siteInfo = useSite({
    listOption: {
      board_type: 5,
      content_type: 5,
      type: 1,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  return (
    <Styles.MainBroadcast>
      <div className="broadcast_inner">
        <div className="broadcast_title">
          {/* <p className="dec">{'SBS <그것이 알고 싶다>, MBC <PD 수첩>, '}<br className="mbBr"/>{'연합뉴스 TV, 채널 A <행복한 아침>'}</p> */}
          {/* <h2 className="tit">온강은 다양한 방송 매체에서<br/><span>형사 사건에 대해 자문</span>을 <br className="mbBr"/>구하고 있습니다.</h2> */}
          <p className="dec">{'다양한 방송 매체에서 온강의 변호사들에게 '}<br className="mbBr"/>{'형사사건 관련한 자문을 구하고 있습니다'}</p>
          <h2 className="tit">언론이 먼저 찾는 법무법인 온강</h2>
        </div>
        <div className="broadcast_list">
          {list.length > 0 && <Swiper
            className="slider"
            slidesPerView={deviceType === 'pc' ? 3 : 1.5}
            centeredSlides={deviceType === 'pc' ? false : true}
            loop={true} 
loopFillGroupWithBlank={true}
            speed={800}
            spaceBetween={deviceType === 'pc' ? 30 : 15}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            onSwiper={(e) => {setSwiper(e)}}
          >
            {list?.map((item, index) => {
              return (
                <SwiperSlide key={"broadcastList" + index}>
                  <NavLink to={"/intro/news/detail/" + item.id}>
                    <div className="broadcast_slide">
                      <img src={IMAGEPATH + item.image} alt="" />
                      <div className="broadcast_text">
                        <p className="tit">{ item.title }</p>
                        <p className="dec">{ item.description_text?.slice(0,20) }</p>
                      </div>
                    </div>
                  </NavLink>
                </SwiperSlide>
              )
            })}
          </Swiper>}
          <button className='slide_btn btn_prev' onClick={handlePrev}>
            <img src={IconPrev} alt="슬라이드 이전" />
          </button>
          <button className='slide_btn btn_next' onClick={handleNext}>
            <img src={IconNext} alt="슬라이드 다음" />
          </button>
        </div>
      </div>
    </Styles.MainBroadcast>
  );
}

export default MainBroadcast;