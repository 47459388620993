import ArticleHeading from "components/layout/ArticleHeading";
import { ColumnList } from "components/layout/ColumnList";
import SearchInput from "components/layout/SearchInput";
import SwiperWrap from "components/layout/SwiperWrap";
import { STORY_COLUMN_LIST_02, STORY_EX_SWIPER_LIST } from "constant";
import { useBoard } from "hooks";
import useSite from "hooks/useSite";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import utils from "utils";

const StoryColumn = () => {
  const [isList, setIsList] = useState(false);
  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      keyword: param.keyword ? param.keyword : "",
      parent_category_info_id: "6",
      category_info_id: "",
      type: 6,
      recordSize: 9,
    };
    setFilterInfo({ ...f });
  }, [location]);

  const boardInfo = useBoard({
    listOption: filterInfo,
  });

  const siteInfo = useSite({
    listOption: {
      board_type: 6,
      content_type: 6,
      type: 1,
    },
  });

  const bestList = siteInfo.listQuery?.data?.list || [];
  const list = boardInfo.listQuery?.data?.list || [];

  useEffect(() => {
    if (bestList.length === 0) setIsList(true);
    if (bestList.length !== 0) setIsList(false);
  }, []);

  return (
    <section className="section story column full">
      <div className="story-column-wrap">
        <div className="column-inner">
          <TopStyle className="top" isList={isList}>
            <ArticleHeading title={"필독 꿀팁"} />
            {bestList.length !== 0 && (
              <SwiperWrap list={bestList} link={`/story/column/detail/`} />
            )}
          </TopStyle>
          <div className="bottom">
            <SearchInput placeholder={"컬럼 검색"} />
            {list.length !== 0 && (
              <ColumnList
                list={list}
                pagination={boardInfo.listQuery?.data?.pagination}
                link={`/story/column/detail/`}
              />
            )}
            {list.length === 0 && (
              <div className="empty-list">검색결과가 없습니다.</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoryColumn;

const TopStyle = styled.div`
  ${(props) =>
    props.isList && `border:none !important; margin-bottom: 0 !important;`};
`;
