import styled from "styled-components";

// img
import BgTopBanner from "resources/image/common/top_banner_bg.jpg";
import BgTopBannerMb from "resources/image/common/top_banner_bg_mb.jpg";

const Container = styled.div`
  height: 60px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background: url(${BgTopBanner}) no-repeat center / cover;

  .swiper {
    height: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 1520px) {
    height: calc(100vw * (60 / 1520));
    font-size: calc(100vw * (24 / 1520));
  }

  @media (max-width: 1024px) {
    height: calc(100vw * (40 / 375));
    font-size: calc(100vw * (13 / 375));
    background: url(${BgTopBannerMb}) no-repeat center / cover;
  }
`;

export { Container };

export default {
  Container,
};
