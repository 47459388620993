import styled, { css } from 'styled-components';

// img
import BnnrImg from "resources/image/main/sub_banner_img.png";
import MbBnnrImg from "resources/image/main/mb_sub_banner_img.jpg";
import QuotesImg from "resources/image/icon/quotes_img_wh.svg";

const SearchLayout = styled.div`
  position: relative;
  width: 100%;
  padding-top: 80px;
  & .tit_box {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 3px solid #000;
    & h3 {
      font-size: 36px;
      line-height: 43px;
      text-align: left;
      font-weight: bold;
    }
    & .btn_more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 154px; height: 45px;
      font-size: 18px;
      font-weight: bold;
      padding: 0 30px;
      border-radius: 30px;
      border: 1px solid #000;
      transition: 0.5s;
      z-index: 1;
      & img {
        width: 18px; height: 18px;
      }
      &:hover {
        background-color: rgba(255,255,255,0.1);
        & img {
          animation: rotate 0.8s ease-in-out forwards;
        }
      }
    }
  }
  & .none {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; height: 200px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #C4C4C4;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding-top: calc(100vw*(80/1520));
    & .tit_box {
      gap: calc(100vw*(30/1520));
      padding-bottom: calc(100vw*(20/1520));
      margin-bottom: calc(100vw*(30/1520));
      & h3 {
        font-size: calc(100vw*(36/1520));
        line-height: calc(100vw*(43/1520));
      }
      & .btn_more {
        width: calc(100vw*(154/1520)); height: calc(100vw*(45/1520));
        font-size: calc(100vw*(18/1520));
        padding: 0 calc(100vw*(30/1520));
        border-radius: calc(100vw*(30/1520));
        & img {
          width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));
        }
      }
    }
    & .none {
      height: calc(100vw*(200/1520));
      font-size: calc(100vw*(18/1520));
      line-height: calc(100vw*(21/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding-top: calc(100vw*(50/375));
    & .tit_box {
      gap: calc(100vw*(10/375));
      padding-bottom: calc(100vw*(20/375));
      margin-bottom: calc(100vw*(30/375));
      & h3 {
        font-size: calc(100vw*(26/375));
        line-height: calc(100vw*(31/375));
      }
      & .btn_more {
        width: calc(100vw*(148/375)); height: calc(100vw*(43/375));
        font-size: calc(100vw*(16/375));
        padding: 0 calc(100vw*(30/375));
        border-radius: calc(100vw*(30/375));
        & img {
          width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
        }
      }
    }
    & .none {
      height: calc(100vw*(300/375));
      font-size: calc(100vw*(16/375));
      line-height: calc(100vw*(19/375));
    }
  }
`;




export {
  SearchLayout
}

export default {
  SearchLayout
}