import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDeviceType } from "hooks/useMediaQuery";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Styles from './styles';

// img
import Img from "resources/image/main/broadcast_img.jpg";
import IconNext from "resources/image/icon/icon_next.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import { IMAGEPATH } from "service/connector";

function Column({list}) {
  const deviceType = useDeviceType();
  SwiperCore.use([Autoplay]);
  const [swiper, setSwiper] = useState(null);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };

  return (
    <Styles.Container>
      {list.length > 0 && 
        <Swiper
          className="slider"
          slidesPerView={deviceType === 'pc' ? 3 : 1.5}
          centeredSlides={deviceType === 'pc' ? false : true}
          loop={list.length > 2}
          speed={800}
          spaceBetween={deviceType === 'pc' ? 30 : 15}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          onSwiper={(e) => {setSwiper(e)}}
        >
          {list?.map((item, index) => {
            return (
              <SwiperSlide key={"columnList" + index}>
                <div className="column_slide" onClick={()=>{ window.location.href = "/story/column/detail/" + item.id}}>
                  <div className="tag">
                    <img src={IMAGEPATH + item.image} alt="" />
                    <i>{item.keyword}</i>
                  </div>
                  <div className="column_text">
                    <p className="tit">{item.title}</p>
                    <p className="dec">{item.description_text}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
      </Swiper>}
      {
        list.length > 2 && 
        <>
          <button className='slide_btn btn_prev' onClick={handlePrev}>
            <img src={IconPrev} alt="슬라이드 이전" />
          </button>
          <button className='slide_btn btn_next' onClick={handleNext}>
            <img src={IconNext} alt="슬라이드 다음" />
          </button>
        </>
      }
    </Styles.Container>
  );
}

export default Column;