import Styles from "./styles";

//img
import IconClose from "resources/image/icon/icon_close_gray.svg";

function ImageModal({ modalOption, modalClose }) {
  return (
    <Styles.Container
      backgroundColor={modalOption.backgroundColor}
      width={modalOption.width}
      onClick={() => {
        if(modalOption.backgroundColor != "rgba(0,0,0,0)"){
          modalOption.callback();
          modalClose();
        }
      }}
    >
      <div
        className={"wrap" + (modalOption.y || modalOption.x ? " on" : "")}
        style={{ top: modalOption.y, left: modalOption.x }}
        onClick={() => {
          if(modalOption.url && modalOption.url?.includes("tel")){
            window.location.href = modalOption.url;
          }else
            window.open(modalOption.url, "_blank");
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: modalOption.description }}
        ></div>
        <button
          type="button"
          className="closeButton"
          onClick={(e) => {
            e.stopPropagation();
            modalOption.callback();
            modalClose();
          }}
        >
          <img src={IconClose} alt="close" />
        </button>
      </div>
    </Styles.Container>
  );
}

export { ImageModal };

export default ImageModal;
