import styled, { css } from "styled-components";

const Counsel = styled.div`
  width: 638px;
  padding: 87px 39px 90px;
  background: #fff;
  box-shadow: 10px 10px 40px 0 rgba(90, 90, 90, 0.25);
  border-top: 5px solid #da250b;
  & .form_inner {
    & h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 44px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #fff;
      background-color: #111;
      border-radius: 50px;
    }
    & p {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      margin-top: 20px;
    }
    & .input_wrap {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 50px;
      & li {
        position: relative;
        & p {
          position: relative;
          font-size: 18px;
          line-height: 21px;
          font-weight: 600;
          padding-left: 12px;
          margin-top: 0;
          &::before {
            content: "";
            position: absolute;
            left: 2px;
            top: 50%;
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: #da250b;
          }
        }
        & .radio_box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 330px;
          row-gap: 15px;
          column-gap: 30px;
          margin-top: 14px;
        }
        & .input_box {
          margin-top: 12px;
          margin-bottom: 14px;
        }
        & .check_box {
          display: flex;
          align-items: center;
          gap: 5px;
          & > span {
            font-size: 16px;
            font-weight: 800;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    & .btn_box {
      position: relative;
      padding-top: 40px;
      & .err {
        position: absolute;
        top: 16px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        color: #da250b;
        & img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
      & .btn {
        width: 100%;
        height: 54px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        border-radius: 100px;
        background-color: #da250b;
      }
      & .aside_dec {
        display: block;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #888;
        margin-top: 14px;
        & a {
          font-weight: 700;
        }
        & br {
          display: none;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw * (638 / 1520));
    padding: calc(100vw * (87 / 1520)) calc(100vw * (39 / 1520)) calc(100vw * (90 / 1520));
    box-shadow: calc(100vw * (10 / 1520)) calc(100vw * (10 / 1520)) calc(100vw * (40 / 1520)) 0 rgba(90, 90, 90, 0.25);
    & .form_inner {
      & h3 {
        width: calc(100vw * (190 / 1520));
        height: calc(100vw * (44 / 1520));
        font-size: calc(100vw * (20 / 1520));
        border-radius: calc(100vw * (50 / 1520));
      }
      & p {
        font-size: calc(100vw * (20 / 1520));
        line-height: calc(100vw * (28 / 1520));
        margin-top: calc(100vw * (20 / 1520));
      }
      & .input_wrap {
        gap: calc(100vw * (40 / 1520));
        margin-top: calc(100vw * (50 / 1520));
        & li {
          & p {
            font-size: calc(100vw * (18 / 1520));
            line-height: calc(100vw * (21 / 1520));
            padding-left: calc(100vw * (12 / 1520));
            &::before {
              left: calc(100vw * (2 / 1520));
              width: calc(100vw * (4 / 1520));
              height: calc(100vw * (4 / 1520));
            }
          }
          & .radio_box {
            width: calc(100vw * (330 / 1520));
            column-gap: calc(100vw * (30 / 1520));
            margin-top: calc(100vw * (14 / 1520));
          }
          & .input_box {
            margin-top: calc(100vw * (12 / 1520));
            margin-bottom: calc(100vw * (14 / 1520));
          }
          & .check_box {
            gap: calc(100vw * (5 / 1520));
            & > span {
              font-size: calc(100vw * (16 / 1520));
            }
          }
        }
      }
      & .btn_box {
        padding-top: calc(100vw * (40 / 1520));
        & .err {
          top: calc(100vw * (16 / 1520));
          font-size: calc(100vw * (16 / 1520));
          & img {
            width: calc(100vw * (18 / 1520));
            height: calc(100vw * (18 / 1520));
            margin-right: calc(100vw * (4 / 1520));
          }
        }
        & .btn {
          height: calc(100vw * (54 / 1520));
          font-size: calc(100vw * (18 / 1520));
          border-radius: calc(100vw * (100 / 1520));
        }
        & .aside_dec {
          font-size: calc(100vw * (16 / 1520));
          margin-top: calc(100vw * (14 / 1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: 100%;
    padding: calc(100vw * (40 / 375)) calc(100vw * (16 / 375)) calc(100vw * (40 / 375));
    box-shadow: calc(100vw * (10 / 375)) calc(100vw * (10 / 375)) calc(100vw * (40 / 375)) 0 rgba(90, 90, 90, 0.25);
    & .form_inner {
      & h3 {
        width: calc(100vw * (170 / 375));
        height: calc(100vw * (39 / 375));
        font-size: calc(100vw * (16 / 375));
        border-radius: calc(100vw * (40 / 375));
      }
      & p {
        font-size: calc(100vw * (16 / 375));
        line-height: calc(100vw * (22 / 375));
        margin-top: calc(100vw * (15 / 375));
        word-break: keep-all;
      }
      & .input_wrap {
        gap: calc(100vw * (30 / 375));
        margin-top: calc(100vw * (30 / 375));
        & li {
          & p {
            font-size: calc(100vw * (16 / 375));
            line-height: calc(100vw * (19 / 375));
            padding-left: calc(100vw * (12 / 375));
            &::before {
              left: calc(100vw * (2 / 375));
              width: calc(100vw * (4 / 375));
              height: calc(100vw * (4 / 375));
            }
          }
          & .radio_box {
            width: 100%;
            column-gap: calc(100vw * (30 / 375));
            row-gap: calc(100vw * (14 / 375));
            margin-top: calc(100vw * (14 / 375));
            & label {
              white-space: nowrap;
            }
          }
          & .input_box {
            margin-top: calc(100vw * (12 / 375));
            margin-bottom: calc(100vw * (14 / 375));
          }
          & .check_box {
            gap: calc(100vw * (5 / 375));
            & > span {
              font-size: calc(100vw * (14 / 375));
            }
          }
        }
      }
      & .btn_box {
        padding-top: calc(100vw * (40 / 375));
        & .err {
          top: calc(100vw * (16 / 375));
          font-size: calc(100vw * (14 / 375));
          & img {
            width: calc(100vw * (18 / 375));
            height: calc(100vw * (18 / 375));
            margin-right: calc(100vw * (4 / 375));
          }
        }
        & .btn {
          height: calc(100vw * (54 / 375));
          font-size: calc(100vw * (16 / 375));
          border-radius: calc(100vw * (100 / 375));
          font-weight: 600;
        }
        & .aside_dec {
          font-size: calc(100vw * (14 / 375));
          margin-top: calc(100vw * (14 / 375));
          & br {
            display: block;
          }
        }
      }
    }
  }
`;

export { Counsel };

export default {
  Counsel,
};
