import Styles from './styles';


function SubBanner() {

  return (
    <Styles.SubBanner>
      <div className="sub_inner">
        <div className='text_wrap'>
          <h2 className='tit'>지금 상황이 <br className='mbBr'/><strong>두렵고 답답하다면</strong><br/>더 이상 지체하지 마세요.</h2>
          <p className='dec'>검찰 조직 내에서도 인정받는 독종 검사들이 뜻을 모았습니다.<br/>
          단 1%의 가능성만 있다면 온강은 절대 포기하지 않습니다.</p>
          <button className='btn' onClick={()=>{ window.location.href = "/counsel"; }}>1:1 비밀 상담</button>  
        </div>
      </div>
    </Styles.SubBanner>
  );
}

export default SubBanner;