import { ICON } from "constant";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import utils from "utils";

const SearchInput = ({ placeholder }) => {
  const [keyword,setKeyword] = useState("");
  const navigate = useNavigate();
  const onClickPage = (value) => {
    navigate(utils.etc.getQueryParamUrl({ keyword : keyword , page : 1, disableScrollTop : true },false))
  };

  return (
    <div className="search-input-form">
      <fieldset>
        <legend>검색</legend>
        <div className="search-box">
          <input 
            className="search-input" 
            type="search" 
            name="keyword" 
            placeholder={placeholder} 
            onChange={(e)=>{setKeyword(e.target.value)}}
            onKeyUp={(e)=>{
              if (e.key == "Enter") {
                onClickPage && onClickPage();
              }
            }}
          />
          <button className="search-svg" type="button" onClick={onClickPage}>
            <img src={ICON.ICON_SEARCH} alt="" />
          </button>
        </div>
      </fieldset>
    </div>
  );
};

export default SearchInput;
