import styled, { css } from 'styled-components';

const SystemLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1260px;
  margin: 0 auto;
  & .system_title {
    & .tit {
      font-size: 56px;
      line-height: 72px;
      font-weight: 400;
      letter-spacing: -0.5px;
      margin-bottom: 30px;
      word-wrap: break-word;
      word-break: keep-all;
      & strong {
        font-weight: 800;
      }
    }
    & .text {
      font-size: 26px;
      line-height: 36px;
      font-weight: 500;
      letter-spacing: -0.5px;
      & span {
        color: #DA250B;
      }
    }
    & .dec {
      font-size: 26px;
      line-height: 36px;
      font-weight: 500;
      margin-top: 20px;
      color: #888;
    }
    & .sub {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 483px; height: 48px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      margin-top: 20px;
      color: #888;
      background-color: #E4E4E4;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw*(1260/1520));
    & .system_title {
      & .tit {
        font-size: calc(100vw*(56/1520));
        line-height: calc(100vw*(72/1520));
        margin-bottom: calc(100vw*(30/1520));
      }
      & .text {
        font-size: calc(100vw*(26/1520));
        line-height: calc(100vw*(36/1520));
      }
      & .dec {
        font-size: calc(100vw*(26/1520));
        line-height: calc(100vw*(36/1520));
        margin-top: calc(100vw*(20/1520));
      }
      & .sub {
        width: calc(100vw*(483/1520)); height: calc(100vw*(48/1520));
        font-size: calc(100vw*(16/1520));
        line-height: calc(100vw*(19/1520));
        margin-top: calc(100vw*(20/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    flex-direction: column;
    width: calc(100vw*(335/375));
    & .system_title {
      width: 100%;
      & .tit {
        font-size: calc(100vw*(36/375));
        line-height: calc(100vw*(43/375));
        margin-bottom: calc(100vw*(20/375));
      }
      & .text {
        font-size: calc(100vw*(20/375));
        line-height: calc(100vw*(24/375));
      }
      & .dec {
        font-size: calc(100vw*(20/375));
        line-height: calc(100vw*(24/375));
        margin-top: calc(100vw*(20/375));
      }
      & .sub {
        width: 100%; height: calc(100vw*(48/375));
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(17/375));
        margin-top: calc(100vw*(15/375));
        margin-bottom: calc(100vw*(30/375));
      }
    }
  }
`;




export {
  SystemLayout
}

export default {
  SystemLayout
}