import styled, { css } from 'styled-components';

// img
import BnnrImg from "resources/image/search/sub_banner_img.png";
import MbBnnrImg from "resources/image/search/mb_sub_banner_img.jpg";

const SubBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; height: 640px;
  background: #000 url(${BnnrImg}) no-repeat center left/auto 640px;
  margin-bottom: -150px;
  & .sub_inner {
    width: 1260px;
    margin: 0 auto;
    color: #fff;
    & .text_wrap {
      width: 560px;
      margin-left: auto;
      & .tit {
        font-size: 46px;
        line-height: 64px;
        text-align: left;
        font-weight: 400;
        margin-bottom: 20px;
        letter-spacing: -0.5px;
        white-space: nowrap;
        & strong {
          font-weight: 800;
          color: #DA250B;
        }
      }
      & .dec {
        width: 100%;
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        font-weight: 400;
        color: #fff;
      }
      & .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 560px; height: 54px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        margin-top: 50px;
        border-radius: 54px;
        color: #fff;
        background-color: #DA250B;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(640/1520));
    background-size: auto calc(100vw*(640/1520));
    margin-bottom: calc(100vw*(-150/1520));
    & .sub_inner {
      width: calc(100vw*(1260/1520));
      color: #fff;
      & .text_wrap {
        width: calc(100vw*(560/1520));
        & .tit {
          font-size: calc(100vw*(46/1520));
          line-height: calc(100vw*(64/1520));
          margin-bottom: calc(100vw*(20/1520));
        }
        & .dec {
          width: 100%;
          font-size: calc(100vw*(18/1520));
          line-height: calc(100vw*(25/1520));
        }
        & .btn {
          width: calc(100vw*(560/1520)); height: calc(100vw*(54/1520));
          font-size: calc(100vw*(18/1520));
          line-height: calc(100vw*(21/1520));
          margin-top: calc(100vw*(50/1520));
          border-radius: calc(100vw*(54/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    align-items: flex-start;
    height: calc(100vw*(667/375));
    background: #000 url(${MbBnnrImg}) no-repeat top center/100%;
    margin-bottom: 0;
    padding-top: calc(100vw*(340/375));
    & .sub_inner {
      width: 100%;
      color: #fff;
      & .text_wrap {
        width: calc(100vw*(335/375));
        margin: 0 auto;
        & .tit {
          font-size: calc(100vw*(30/375));
          line-height: calc(100vw*(42/375));
          margin-bottom: calc(100vw*(30/375));
        }
        & .dec {
          width: 100%;
          font-size: calc(100vw*(14/375));
          line-height: calc(100vw*(20/375));
        }
        & .btn {
          width: calc(100vw*(300/375)); height: calc(100vw*(54/375));
          font-size: calc(100vw*(16/375));
          line-height: calc(100vw*(19/375));
          margin-top: calc(100vw*(30/375));
          border-radius: calc(100vw*(54/375));
        }
      }
    }
  }
`;




export {
  SubBanner
}

export default {
  SubBanner
}