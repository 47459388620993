import { useState } from "react";
import Styles from "./styles";
import { Mobile, PC, useDeviceType } from "hooks/useMediaQuery";
// scroll
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
// img
import IconRight from "resources/image/icon/icon_arrow_right.svg";
import IconLeft from "resources/image/icon/icon_arrow_left.svg";
import useSite from "hooks/useSite";

function MainMonthly() {
  const deviceType = useDeviceType();
  const [tabActive, setTabActive] = useState(0);

  const siteInfo = useSite({
    groupDateOption: {
      board_type: 2,
      content_type: 8,
      type: 1,
    },
  });

  const list = siteInfo.listGroupDateQuery?.data?.data || [];

  const siteGroupInfo = useSite({
    groupOption: {
      board_type: 2,
      content_type: 8,
      type: 1,
      started_at: list[tabActive] || "",
    },
  });

  const groupList = siteGroupInfo.listGroupQuery?.data?.data || [];

  const calcTop = () => {
    if (deviceType === "pc") return tabActive * 75;
    if (deviceType === "mobile") {
      return tabActive * 68 + 10 * tabActive;
    }
  };

  const handlePrev = () => {
    if (tabActive !== list.length - 1) setTabActive(tabActive + 1);
  };

  const handleNext = () => {
    if (tabActive > 0) setTabActive(tabActive - 1);
  };

  return (
    <Styles.MainMonthly>
      <div className="monthly_inner">
        <div className="tab_menu">
          <h2>
            법무법인 온강
            <br />
            <b>성공사례</b>
            <br className="pcBr" /> 월별 요약
          </h2>
          <Styles.TabBtn className="tab_btn" top={calcTop}>
            {list?.map((item, index) => {
              return (
                <li
                  className={tabActive === index ? "active" : ""}
                  onClick={() => {
                    setTabActive(index);
                  }}
                  key={"tabList" + index}
                >
                  {item?.replace("-", ".")}
                </li>
              );
            })}
          </Styles.TabBtn>
        </div>
        <div className="tab_contents">
          <div className="tab_navi">
            <button
              onClick={() => {
                handlePrev();
              }}
            >
              <img src={IconLeft} alt="" />
            </button>
            <PC>
              <p>{list[tabActive]?.replace("-", ".")}</p>
            </PC>
            <Mobile>
              <p>{list[tabActive + 1]?.replace("-", ".").split(".")[1]}</p>
              <p className="active">
                {list[tabActive]?.replace("-", ".").split(".")[1]}
              </p>
              <p>{list[tabActive - 1]?.replace("-", ".").split(".")[1]}</p>
            </Mobile>
            <button
              onClick={() => {
                handleNext();
              }}
            >
              <img src={IconRight} alt="" />
            </button>
          </div>

          <OverlayScrollbarsComponent className="monthly_list_wrap">
            <ul className="monthly_list">
              {groupList.map((value, index) => {
                return (
                  <li key={"groupList" + index}>
                    <p>{value.title}</p>
                    <span>{value.subtitle}</span>
                  </li>
                );
              })}
            </ul>
          </OverlayScrollbarsComponent>
        </div>
      </div>
    </Styles.MainMonthly>
  );
}

export default MainMonthly;
