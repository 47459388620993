import { useState, useEffect } from "react";
import Styles from "./styles";
import { Radio, Input } from "components/ui/input";
import { useModals } from "hooks";
import { useQuestion } from "hooks";

// img
import IconNext from "resources/image/icon/icon_next.svg";
import IconErr from "resources/image/icon/icon_err.svg";

function System() {
  const modalOption = useModals();

  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [activeItem, setActiveItem] = useState(null);
  const [err, setErr] = useState(1);
  const [targetNextId,setTargetNextId] = useState(1);

  const questionInfo = useQuestion({
    listOption : {
      next_info_id : targetNextId
    }
  });

  const errMessage = (type) => {
    switch (type) {
      case 0:
        return '항목을 선택해 주세요.';
      case 1:
        return '성명을 입력해 주세요.';
      case 2:
        return '연락처를 입력해 주세요.';
      default:
        return ;
    }
  };
  const list = questionInfo.listQuery?.data?.data || [];

  const modalShow = () => {
    modalOption.setArticleModal((e) => {
      e.show = true;
      return { ...e };
    });
  }

  const doNext = () => {
    if(activeItem != null)
      setTargetNextId(activeItem?.next_info_id != 0 ? activeItem?.next_info_id : activeItem?.id);
      setStep(1);
  }

  return (
    <Styles.System className="System_form">
      {
        step === 0 &&
        <div className="form_inner">
          <span className="badge">Q1</span>
          <h3>{list[0]?.title}</h3>
          <div className="radio_box">
            {
              list[0]?.list?.map((value,index)=>{
                return (
                  <Radio
                    labelText={value.title}
                    id={"type0" + value.id}
                    name="type"
                    checked={activeItem?.id == value.id}
                    key={value.id + index}
                    onChange={(e) => {
                      setActiveItem(value);
                    }}
                  />
                )
              })
            }
          </div>
          <span className="err"><img src={IconErr} alt="error" />{errMessage(0)}</span>
          <button className="next_btn" onClick={doNext}>다음으로 <img src={IconNext} alt="" /></button>
        </div>
      }
      {
        step === 1 &&
        <div className="form_inner">
          <h3>성범죄 죄명찾기 <button className="btn" onClick={()=>{setStep(0)}}>처음으로</button></h3>
          <div className="form_box">
            <ul className="provisions_list">
              <li className="row">
                <span className="sub">성폭력범죄의처벌에관한특례법위반(13세미만미성년자준강간/준유사성행위/준강제추행)</span>
                <p className="tit">성폭력범죄의처벌에관한특계법위반</p>
                <span className="sub">안내안내안내안내안내</span>
              </li>
              <li className="row">
                <span className="sub">형법상 공연음란죄</span>
                <p className="tit">성폭력범죄의처벌에관한특계법위반</p>
                <button className="btn" onClick={()=>{modalShow()}}>내용보기</button>
              </li>
            </ul>
            <div className="form_input">
              <p className="info_text">성범죄는 죄명에 따라 처벌 결과가 달라질 수 있습니다.
                <br/><b>내 사건의 정확한 적용 죄목이 궁금하다면 연락처를 남겨주세요.</b>
              </p>
              <ul className="input_wrap">
                <li className="text_list">
                  <p>성명</p>
                  <div className="input_box">
                    <Input
                      placeholder="성명을 입력해주세요."
                      value={name}
                      setValue={setName}
                      onChange={(e) => {setName(e.target.value)}}
                    />
                  </div>
                </li>
                <li className="text_list">
                  <p>연락처</p>
                  <div className="input_box">
                    <Input
                      placeholder="숫자만 입력해 주세요."
                      value={tel}
                      setValue={setTel}
                      onChange={(e) => {
                        let text = e.target.value;
                        text = text.replace(/[^0-9]/gi, "");
                        text = text.replace(/-/gi, "");
                        text =
                          text?.substr(0, 3) +
                          (text?.length > 3
                            ? "-" +
                              text?.substr(3, 4) +
                              (text?.length > 7 ? "-" + text?.substr(7, 4) : "")
                            : "");
                        setTel(text);
                      }}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <span className="err"><img src={IconErr} alt="error" />{errMessage(1)}</span>
          <button className="submit_btn" onClick={()=>{}}>변호사의 정확한 진단 요청하기</button>
        </div>
      }
    </Styles.System>
  );
}

export { System };

export default System;
