import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(100vw * (4 / 375));
  width: 100%;
  height: calc(100vw * (56 / 375));
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 90;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .linkWrap {
    border-radius: calc(100vw * (5 / 375));

    &:not(.kakao) {
      color: #fff;
    }

    &.tel {
      background-color: #34b74b;
    }

    &.kakao {
      color: #2f2f2f;
      background-color: #ffc800;
    }

    &.counsel {
      background-color: #da250b;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw * (118 / 375));
      height: calc(100vw * (44 / 375));
      font-size: calc(100vw * (16 / 375));
      font-weight: bold;

      .icon {
        width: calc(100vw * (30 / 375));
        height: calc(100vw * (30 / 375));
        margin-right: calc(100vw * (5 / 375));
      }
    }
  }

  .stateContainer {
    position: absolute;
    bottom: calc(100% + calc(100vw * (2 / 375)));
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (10 / 375));
    width: calc(100vw * (335 / 375));
    height: calc(100vw * (34 / 375));
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: calc(100vw * (20 / 375));

    .couselState {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: calc(100vw * (7 / 375));
      width: calc(100vw * (92 / 375));
      height: calc(100vw * (24 / 375));
      font-size: calc(100vw * (14 / 375));
      font-weight: bold;
      background-color: #34b74b;
      border-radius: calc(100vw * (5 / 375));

      .icon {
        display: flex;
        align-items: flex-end;
        gap: 1px;
        width: calc(100vw * (15 / 375));
        height: calc(100vw * (16 / 375));
        padding-left: 1px;

        .bar {
          width: calc(100vw * (2 / 375));
          opacity: 0;
          background-color: #fff;

          &:first-child {
            height: calc(100vw * (3 / 375));
            animation: wifi 0.4s linear;
            animation-fill-mode: forwards;
          }

          &:nth-child(2) {
            height: calc(100vw * (6 / 375));
            animation: wifi 0.4s linear;
            animation-delay: 0.4s;
            animation-fill-mode: forwards;
          }

          &:nth-child(3) {
            height: calc(100vw * (10 / 375));
            animation: wifi 0.4s linear;
            animation-delay: 0.8s;
            animation-fill-mode: forwards;
          }

          &:nth-child(4) {
            height: calc(100vw * (13 / 375));
            animation: wifi 0.4s linear;
            animation-delay: 1.2s;
            animation-fill-mode: forwards;
          }

          &:last-child {
            height: 100%;
            animation: wifi 0.4s linear;
            animation-delay: 1.6s;
            animation-fill-mode: forwards;
          }

          @keyframes wifi {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
      }
    }

    .time {
      font-size: calc(100vw * (14 / 375));
      font-weight: 500;
    }

    .time span {
      animation: blink 1s steps(1, start) infinite;
    }

    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }

    .stateText {
      font-size: calc(100vw * (16 / 375));
      font-weight: bold;
    }
  }
`;

export { Container };

export default {
  Container,
};
