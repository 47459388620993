import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FloatHeading from "components/layout/FloatHeading";
import Intro from "./components/Intro";
import IntroSpecial from "./components/IntroSpecial";
import IntroExpert from "./components/IntroExpert";
import IntroNews from "./components/IntroNews";
import IntroMap from "./components/IntroMap";

import { INTRO_HEADING_DATA } from "constant";
import IntroExpertTopBanner from "./components/IntroExpertTopBanner";

const IntroPage = () => {
  const { contents } = useParams();
  const renderComponents = {
    introduce: <Intro />, // 2-1 온강 소개
    special: <IntroSpecial />, // 2-2 온강의 특별함
    news: <IntroNews />, // 2-4 언론보도
    map: <IntroMap />, // 2-5 오시는 길
  };

  return (
    <div className="intro page">
      {contents === "expert" ? (
        <div className="inner expert">
          <FloatHeading data={INTRO_HEADING_DATA} />
          {/* <IntroExpertTopBanner /> */}
          <IntroExpert />
        </div>
      ) : (
        <div className="inner">
          <FloatHeading data={INTRO_HEADING_DATA} />
          {renderComponents[contents] || <div>Error!{contents}</div>}
        </div>
      )}
    </div>
  );
};

export default IntroPage;
