import styled from "styled-components";
import { SwiperWrapStyle } from "../SwiperWrap/styles";

const CategorySwiperWrap = styled(SwiperWrapStyle)`
  .category-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    margin-left: 120px;
  }

  & .sw-container.youtube {
    .title {
      font-size: 18px;
      min-height: 52px;
      margin-bottom: 0;
    }
    & .swiper_wrap {
      .swiper {
        margin: 30px 120px 0 120px;

        & .swiper-slide {
          & .img-box-container {
            height: 190px;
            .img-box {
              height: 100%;
            }
            img {
              height: 100%;
              object-fit: cover;
            }
            margin-bottom: 30px;
          }

          &:hover {
            .img-box {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1520px) {
    .category-title {
      font-size: calc(100vw * (36 / 1520));
      line-height: calc(100vw * (43 / 1520));
      margin-left: calc(100vw * (120 / 1520));
    }

    & .sw-container.youtube {
      .title {
        font-size: calc(100vw * (18 / 1520));
        min-height: calc(100vw * (52 / 1520));
      }
      & .swiper_wrap {
        .swiper {
          margin: calc(100vw * (30 / 1520)) calc(100vw * (120 / 1520)) 0 calc(100vw * (120 / 1520));

          & .swiper-slide {
            & .img-box-container {
              height: calc(100vw * (190 / 1520));
              margin-bottom: calc(100vw * (30 / 1520));
            }
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .category-title {
      font-size: calc(100vw * (26 / 375));
      line-height: normal;
      margin-left: calc(100vw * (20 / 375));
    }

    & .sw-container.youtube {
      .title {
        font-size: calc(100vw * (18 / 375));
        min-height: calc(100vw * (52 / 375));
      }
      & .swiper_wrap {
        .swiper {
          width: 100%;
          max-width: calc(100vw * (221 / 375));
          margin: calc(100vw * (15 / 375)) 0 0 0;
          padding: 0;

          & .swiper-slide {
            & .img-box-container {
              height: calc(100vw * (152 / 375));
              margin-bottom: calc(100vw * (15 / 375));
            }
          }
        }
      }
    }

    & .slide_btn {
      width: calc(100vw * (34 / 375));
      display: block;
      left: initial;
      &.btn_prev {
        left: calc(100vw * (20 / 375));
      }
      &.btn_next {
        left: initial;
        right: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { CategorySwiperWrap };

export default { CategorySwiperWrap };
