import Styles from "./styles";

//img
import IconClose from "resources/image/icon/icon_close.svg";

function ArticleModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="wrap">
        <button type="button" className="closeButton" onClick={modalClose}>
          닫기
          <img src={IconClose} alt="close" />
        </button>
        <h2 className="title">관련조문 내용보기</h2>
        <div className="info">
          <span>성범죄는 벌금형 이상의 판결이 나오는 순간 <br className="mbBr"/>국가 시스템에 성범죄자로 등록되며, <br className="mbBr"/>취업/해외여행 등에 제한이 생길 수 있습니다.</span>
        </div>
        <div className="inner">
          <p className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </div>
        <div className="info">
          <span>본 죄명찾기는 의뢰인의 이해를 돕기 위해 개발되었으며, <br className="mbBr"/>정확한 결과를 제시하고 있지 않습니다.
            <br/>상황에 따라 확률은 달라질 수 있으니 <br className="mbBr"/>반드시 전문가와 상담이 필요합니다.
          </span>
        </div>
      </div>
      <div className="overlay" onClick={modalClose}></div>
    </Styles.Container>
  );
}

export { ArticleModal };

export default ArticleModal;
