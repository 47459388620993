import { useEffect, useRef, useState } from "react";
import Styles from "./styles";
import { useDeviceType } from "hooks/useMediaQuery";

// img
import ProcessImg01 from "resources/image/main/process_img_01.jpg";
import ProcessImg02 from "resources/image/main/process_img_02.jpg";
import ProcessImg03 from "resources/image/main/process_img_03.jpg";
import ProcessImg04 from "resources/image/main/process_img_04.jpg";

function MainProcess() {
  const deviceType = useDeviceType();
  const [tabActive, setTabActive] = useState(0);
  const tabList = [
    "부장검사·검사·군검사출신\n변호사들로 구성",
    "현직 경찰을 교육한\n검사출신변호사의 공동 대응",
    "사전 조사 시뮬레이션",
    "조사 전 포렌식 자료 확보",
  ];

  const calcTop = () => {
    if (deviceType === "pc") return tabActive * 100;
    if (deviceType === "mobile") {
      return tabActive * 68 + 10 * tabActive;
    }
  };

  const intervalId = useRef(null);
  useEffect(() => {
    intervalId.current = setInterval(() => {
      setTabActive((prev) => (prev + 1 > 3 ? 0 : prev + 1));
    }, 2000);
    return () => clearInterval(intervalId.current);
  }, []);

  return (
    <Styles.MainProcess>
      <div className="process_inner">
        <div className="tab_menu">
          <h2>
            “왜” <span>형사</span>는 온강인가?
          </h2>
          <Styles.TabBtn className="tab_btn" top={calcTop}>
            {tabList?.map((item, index) => {
              return (
                <li
                  className={tabActive === index ? "active" : ""}
                  onClick={() => {
                    setTabActive(index);
                    clearInterval(intervalId.current);
                  }}
                  key={"tabList" + index}
                >
                  <span>0{index + 1}</span>
                  <p>{item}</p>
                </li>
              );
            })}
          </Styles.TabBtn>
        </div>
        <div className="tab_contents">
          <div className={"tab_box" + (tabActive === 0 ? " active" : "")}>
            <img src={ProcessImg01} alt="" />
            <p>
              군검사·검사 출신 변호사들이 함께하여 <br />
              형사사건에 대한 종합적 법률 전략 제공
            </p>
          </div>
          <div className={"tab_box" + (tabActive === 1 ? " active" : "")}>
            <img src={ProcessImg02} alt="" />
            <p>
              현직 경찰 교육 경험을 가진 검사출신 변호사가 수사 기관의 최신
              동향과 기법을 파악하여 선제적 대응
            </p>
          </div>
          <div className={"tab_box" + (tabActive === 2 ? " active" : "")}>
            <img src={ProcessImg03} alt="" />
            <p>
              형사사건 조사 연습실에서 검사출신 변호사와
              <br />
              실전 대응 시뮬레이션으로 수사 상황 대비
            </p>
          </div>
          <div className={"tab_box" + (tabActive === 3 ? " active" : "")}>
            <img src={ProcessImg04} alt="" />
            <p>
              한국디지털포렌식센터와 협업을 통해
              <br className="pcBr" /> 전문적인 <br className="mbBr" />
              포렌식 수사 및 신속한 디지털 증거 확보
            </p>
          </div>
        </div>
      </div>
    </Styles.MainProcess>
  );
}

export default MainProcess;
