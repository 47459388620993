import { useState } from "react";
import { Example, Column, Youtube, Review } from "../../components";
import Styles from './styles';

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";

const Layout = ({type, list}) => {
  switch (type) {
    case 0:
      return <Example list={list}/>
    case 1:
      return <Column list={list}/>
    case 2:
      return <Youtube list={list}/>
    case 3:
      return <Review list={list}/>
    default:
      return ;
  }
};

function SearchLayout({type, title, search, list}) {

  return (
    <Styles.SearchLayout>
      <div className="tit_box">
        <h3>{ title }</h3>
        <button className='btn_more' onClick={()=>{ window.location.href = search }}>더보기<img src={IconPlus} alt="more view" /></button>
      </div>
      { list.length > 0 ? <Layout type={type} list={list} /> : <p className="none">검색결과가 없습니다.</p> }
      
    </Styles.SearchLayout>
  );
}

export default SearchLayout;