import styled from "styled-components";

const Header = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 5;
  transition: background 0.5s;
  color: #fff;
  overflow: hidden;
  &.on {
    background-color: #fff;
    color: #111;
  }
  &.open {
    height: 369px;
    border-bottom: 1px solid #f4f4f4;
    & .header::after {
      content: "";
      position: absolute;
      top: 80px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f4f4f4;
    }
  }
  &.active {
    position: fixed;
    top: 0;
  }
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1260px;
    margin: 0 auto;
  }
  & .logo {
    width: 152px;
    height: 29px;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .gnb {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 80px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        width: 0;
        height: 1px;
        background-color: #da250b;
        transition: 0.4s;
      }
      & span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
      }
      &:hover::after {
        width: 100%;
      }
      & .lnb {
        position: absolute;
        top: 80px;
        display: flex;
        flex-direction: column;
        gap: 26px;
        width: 120px;
        padding-top: 30px;
        & li {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 19px;
          cursor: pointer;
          &::after {
            content: "";
            display: none;
            position: absolute;
            left: -10px;
            top: 50%;
            bottom: auto;
            width: 5px;
            height: 5px;
            background-color: #da250b;
            border-radius: 50%;
            transform: translateY(-50%);
          }
          & span {
            display: block;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
          }
          &:hover {
            color: #da250b;
            &::after {
              display: block;
              width: 5px;
            }
          }
        }
      }
      &:first-child .lnb {
        padding-left: 16px;
      }
      &:nth-child(2) .lnb {
        padding-left: 32px;
      }
      &:nth-child(3) .lnb {
        padding-left: 19px;
      }
      &:nth-child(4) .lnb {
        padding-left: 10px;
      }
      &:nth-child(5) .lnb {
        padding-left: 20px;
      }
      &:last-child .lnb {
        padding-left: 10px;
      }
    }
  }
  & .btn_counsel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    width: 105px;
    height: 80px;
    background: #da250b;
    & img {
      width: 28px;
      height: 28px;
    }
    & span {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    }
  }
  /* 테블릿 */
  @media (max-width: 1520px) {
    top: calc(100vw * (60 / 1520));
    height: calc(100vw * (80 / 1520));
    &.open {
      height: calc(100vw * (369 / 1520));
      & .header::after {
        top: calc(100vw * (80 / 1520));
      }
    }
    & .header {
      width: calc(100vw * (1260 / 1520));
    }
    & .logo {
      width: calc(100vw * (152 / 1520));
      height: calc(100vw * (29 / 1520));
    }
    & .gnb {
      gap: calc(100vw * (20 / 1520));
      & li {
        width: calc(100vw * (120 / 1520));
        height: calc(100vw * (80 / 1520));
        & span {
          font-size: calc(100vw * (16 / 1520));
          line-height: calc(100vw * (19 / 1520));
        }
        & .lnb {
          top: calc(100vw * (80 / 1520));
          gap: calc(100vw * (26 / 1520));
          width: calc(100vw * (120 / 1520));
          padding-top: calc(100vw * (30 / 1520));
          & li {
            height: calc(100vw * (19 / 1520));
            &::after {
              left: calc(100vw * (-10 / 1520));
              width: calc(100vw * (5 / 1520));
              height: calc(100vw * (5 / 1520));
            }
            & span {
              font-size: calc(100vw * (16 / 1520));
              line-height: calc(100vw * (19 / 1520));
            }
            &:hover {
              &::after {
                width: calc(100vw * (5 / 1520));
              }
            }
          }
        }
        &:first-child .lnb {
          padding-left: calc(100vw * (16 / 1520));
        }
        &:nth-child(2) .lnb {
          padding-left: calc(100vw * (32 / 1520));
        }
        &:nth-child(3) .lnb {
          padding-left: calc(100vw * (19 / 1520));
        }
        &:nth-child(4) .lnb {
          padding-left: calc(100vw * (10 / 1520));
        }
        &:last-child .lnb {
          padding-left: calc(100vw * (24 / 1520));
        }
      }
    }
    & .btn_counsel {
      gap: calc(100vw * (7 / 1520));
      width: calc(100vw * (105 / 1520));
      height: calc(100vw * (80 / 1520));
      & img {
        width: calc(100vw * (28 / 1520));
        height: calc(100vw * (28 / 1520));
      }
      & span {
        font-size: calc(100vw * (16 / 1520));
      }
    }
  }
`;

export { Header };

export default {
  Header,
};
