import React from "react";

import FloatHeading from "components/layout/FloatHeading";
import { IMG, STORY_HEADING_DATA } from "constant";
import ArticleHeading from "components/layout/ArticleHeading";
import ImgBox from "components/layout/ImgBox";
import { useBoard } from "hooks";
import { useParams } from "react-router-dom";
import utils from "utils";
import { IMAGEPATH } from "service/connector";

const StoryReviewDetailPage = () => {
  const param = useParams();
  const boardInfo = useBoard({
    detailOption: { id : param.id }
  });

  const detail = boardInfo.detailQuery?.data?.data || {};

  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={STORY_HEADING_DATA} />

        <section className="section export edit story full ">
          <div className="expert-editor-wrap">
            <div className="editor-head">
              <div className="head-inner">
                <div className="head-box">
                  <div className="text-wrap">
                    <p className="title">{detail.title}</p>
                    <p className="sub-title">{utils.date.getDateType(detail.created_at)}</p>
                  </div>
                  <div className="img-box">
                    <ImgBox src={IMAGEPATH + detail.image || IMG.STORY_SW_EX_IMG} />
                  </div>
                </div>
              </div>
            </div>
            <div  className="editor-box editor ck-content" style={{minHeight:400, }} dangerouslySetInnerHTML={{__html : detail?.description}}></div>
          </div>

          <article className="other-list">
            <div className="btn-box">
              <button type="button" className="btn" onClick={()=>{ window.history.back(); }}>
                목록으로 돌아가기
              </button>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default StoryReviewDetailPage;
