import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  & .example {
    display: flex;
    gap: 20px;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(196,196,196,0.2);
    cursor: pointer;
    & .example_img {
      width: 102px;
      height: 145px;
      object-fit: cover;
    }
    & .example_content {
      width: calc(100% - 122px);
      padding-right: 128px;
      & .tag {
        display: inline-block;
        padding: 6px 30px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
        color: #fff;
        background-color: #da250b;
        margin-bottom: 14px;
      }
      & .title_box {
        display: flex;
        align-items: center;
        gap: 20px;
        & .example_tit {
          font-size: 32px;
          line-height: 38px;
          font-weight: bold;
        }
        & .date_box {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: #888;
          & .group {
            display: flex;
            align-items: center;
            gap: 10px;
            & b {
              font-weight: bold;
            }
            &::before {
              content: "";
              display: block;
              width: 1px;
              height: 14px;
              background-color: #e4e4e4;
            }
          }
        }
      }
      & .dec {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #888;
        margin-top: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    gap: calc(100vw * (30 / 1520));
    & .example {
      gap: calc(100vw * (20 / 1520));
      padding-bottom: calc(100vw * (30 / 1520));
      & .example_img {
        width: calc(100vw * (102 / 1520));
        height: calc(100vw * (145 / 1520));
      }
      & .example_content {
        width: calc(100% - calc(100vw * (122 / 1520)));
        padding-right: calc(100vw * (128 / 1520));
        & .tag {
          padding: calc(100vw * (6 / 1520)) calc(100vw * (30 / 1520));
          font-size: calc(100vw * (18 / 1520));
          line-height: calc(100vw * (21 / 1520));
          margin-bottom: calc(100vw * (14 / 1520));
        }
        & .title_box {
          gap: calc(100vw * (20 / 1520));
          & .example_tit {
            font-size: calc(100vw * (32 / 1520));
            line-height: calc(100vw * (38 / 1520));
          }
          & .date_box {
            gap: calc(100vw * (10 / 1520));
            font-size: calc(100vw * (14 / 1520));
            line-height: calc(100vw * (17 / 1520));
            & .group {
              gap: calc(100vw * (10 / 1520));
              &::before {
                width: calc(100vw * (1 / 1520));
                height: calc(100vw * (14 / 1520));
              }
            }
          }
        }
        & .dec {
          font-size: calc(100vw * (14 / 1520));
          line-height: calc(100vw * (20 / 1520));
          margin-top: calc(100vw * (20 / 1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    gap: calc(100vw * (30 / 375));
    & .example {
      gap: calc(100vw * (20 / 375));
      padding-bottom: calc(100vw * (30 / 375));
      border-bottom-width: 2px;
      & .example_img {
        width: calc(100vw * (102 / 375));
        height: calc(100vw * (145 / 375));
      }
      & .example_content {
        width: calc(100% - calc(100vw * (112 / 375)));
        padding-right: 0;
        & .tag {
          padding: calc(100vw * (5 / 375)) calc(100vw * (20 / 375));
          font-size: calc(100vw * (14 / 375));
          line-height: calc(100vw * (17 / 375));
          margin-bottom: calc(100vw * (10 / 375));
        }
        & .title_box {
          flex-direction: column;
          align-items: flex-start;
          gap: calc(100vw * (10 / 375));
          & .example_tit {
            font-size: calc(100vw * (20 / 375));
            line-height: calc(100vw * (26 / 375));
          }
          & .date_box {
            gap: calc(100vw * (10 / 375));
            font-size: calc(100vw * (14 / 375));
            line-height: calc(100vw * (20 / 375));
            & .group {
              gap: calc(100vw * (10 / 375));
              &::before {
                width: calc(100vw * (1 / 375));
                height: calc(100vw * (14 / 375));
              }
            }
          }
        }
        & .dec {
          font-size: calc(100vw * (14 / 375));
          line-height: calc(100vw * (20 / 375));
          margin-top: calc(100vw * (10 / 375));
        }
      }
    }
  }
`;




export {
  Container
}

export default {
  Container
}