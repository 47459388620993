import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import useModals from "hooks/useModals";

export default function usePopup({
  listOption,
}) {

  const modalOption = useModals();

  const indexRef = useRef(0);

  const listQuery = useQuery(
    ["popup_list", listOption],
    () => {
      return listOption && api.popup.getList(listOption);
    },
    {
      enabled: !!listOption,
      refetchOnWindowFocus: false,
      onSuccess:(e)=>{
        let index = indexRef.current;
        openPopup(e.list, index);
      }
    }
  );


  const openPopup = (list, index) => {
    let target = list[index];
    if(!target)return;

    setTimeout(()=>{

      modalOption.setImageModal((e) => {
        e.show = true;
        e.x = `${target.x || 0}px`;
        e.y = `${target.y || 0}px`;
        e.description = target?.description || "";
        e.backgroundColor = target?.background_color || "";
        e.url = target?.url || "#";
        e.width = target?.width || "300";
        e.callback = () => {
          indexRef.current = indexRef.current + 1;
          openPopup(list, indexRef.current);
        }
        return { ...e };
      });
      
    }, parseInt(target?.seconds || 0) * 1000);
  }

  return {
    listQuery,
  };
}
