import { useState, useEffect } from "react";
import Styles from './styles';
import { useNavigate } from "react-router-dom";
import utils from "utils";
import { useCategory } from "hooks";

function Tab() {

  const categoryInfo = useCategory({
    listOption:{
      type:2,
      parent_info_id:"2"
    }
  });  
  
  const navigate = useNavigate();
  
  const list = [{id:0,title:"전체"}].concat(categoryInfo.list || []);
  const categoryId = utils.etc.getSearchParam().category_info_id || 0;


  return (
    <Styles.Tab>
      { list.map((v,i) => {
        return (
          <li
            key={i + "tabList"}
            className={v.id == categoryId ? "active" : ""}
            onClick={()=>{
              navigate(utils.etc.getQueryParamUrl({ page : 1 , category_info_id : i == 0 ? "" : v.id, disableScrollTop : true }))
            }}
          >{v.title}</li>
        )})
      }
    </Styles.Tab>
  );
}

export { Tab };

export default Tab;
