import Styles from "./styles";

// img
import IconYoutube from "resources/image/icon/icon_youtube.svg";
import { useSite } from "hooks";

function MainYoutube() {
  const siteInfo = useSite({
    listOption: {
      board_type: 3,
      content_type: 3,
      type: 1,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  return (
    <Styles.MainYoutube>
      <div className="youtube_inner">
        <div className="youtube_title">
          <h2 className="tit">
            인생 변호사 채널에서만 <br className="mbBr" />
            들을 수 있는
            <br className="pcBr" />
            <b>
              {" "}
              전직 <br className="mbBr" />
              검사들
            </b>
            의 <b>형사사건 꿀팁!</b>
          </h2>
          <p className="dec">의뢰인들이 궁금해하는 모든 것</p>
          <div className="youtube">
            <img src={IconYoutube} alt="" />
            <p>
              지금 <span>영상</span>으로 확인하세요.
            </p>
          </div>
        </div>
        <ul className="youtube_list">
          <li className="main">
            <iframe
              src={list && list[2]?.url}
              title="YouTube video player"
              frameBorder="0"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              // referrerPolicy="strict-origin-when-cross-origin"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
            ></iframe>
          </li>
          <li>
            <iframe
              src={list && list[1]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </li>
          <li>
            <iframe
              src={list && list[0]?.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerPolicy="strict-origin-when-cross-origin"
            ></iframe>
          </li>
        </ul>

        <button type="button" className="moreButton" onClick={() => { window.location.href = "/story/youtube"; }}>
          더보기
        </button>
      </div>
    </Styles.MainYoutube>
  );
}

export default MainYoutube;
