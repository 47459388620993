import Input from "./Input";
import InputSearch from "./InputSearch";
import Checkbox from "./Checkbox";
import Radio from "./Radio";

export {
    Input,
    InputSearch,
    Checkbox,
    Radio
}

export default {
    Input,
    InputSearch,
    Checkbox,
    Radio
};