import { api } from "service";
import { useInfiniteQuery, useQuery } from "react-query";

export default function useSite({ listOption,listCategoryOption, groupOption, groupDateOption }) {
  const listQuery = useQuery(
    ["site_list", listOption],
    () => {
      return listOption && api.site.getList(listOption);
    },
    {
      enabled: !!listOption,
      refetchOnWindowFocus: false,
    }
  );
  const listCategoryQuery = useQuery(
    ["site_category_list", listCategoryOption],
    () => {
      return listCategoryOption && api.site.getCategoryList(listCategoryOption);
    },
    {
      enabled: !!listCategoryOption,
      refetchOnWindowFocus: false,
    }
  );
  const listGroupDateQuery = useQuery(
    ["site_group_date_list", groupDateOption],
    () => {
      return groupDateOption && api.site.getGroupDateList(groupDateOption);
    },
    {
      enabled: !!groupDateOption,
      refetchOnWindowFocus: false,
    }
  );
  const listGroupQuery = useQuery(
    ["site_group_list", groupOption],
    () => {
      return groupOption && api.site.getGroupList(groupOption);
    },
    {
      enabled: !!groupOption,
      refetchOnWindowFocus: false,
    }
  );

  return {
    listQuery,
    listGroupDateQuery,
    listCategoryQuery,
    listGroupQuery,
  };
}
