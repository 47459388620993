import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  .sw-story {
    border: 1px solid #e4e4e4;
    min-height: 224px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding: 60px 15px 0 15px;
    text-align: left;

    & .type-chip {
      font-size: 18px;
      line-height: 21px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      padding: 8px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #da250b;
      position: absolute;
      left: 0;
      top: 0;
    }

    & .title {
      font-size: 18px;
      line-height: 27px;
      font-weight: 800;
      letter-spacing: -0.5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    & .sub_title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: #888;
      margin-top: 20px;
      letter-spacing: -0.3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  & .slide_btn {
    position: absolute;
    top: 50%;
    width: 70px; height: 70px;
    transform: translateY(-50%);
    z-index: 1;
    &.btn_prev {
      left: 50%;
      margin-left: -750px;
    }
    &.btn_next {
      right: 50%;
      margin-right: -750px;
    }
  }
    

  /* 테블릿 */
  @media (max-width: 1520px) {
    .sw-story {
      min-height: calc(100vw * (224 / 1520));
      padding: calc(100vw * (60 / 1520)) calc(100vw * (15 / 1520)) 0 calc(100vw * (15 / 1520));

      & .type-chip {
        font-size: calc(100vw * (18 / 1520));
        line-height: calc(100vw * (21 / 1520));
        padding: calc(100vw * (8 / 1520)) calc(100vw * (30 / 1520));
      }
      & .title {
        font-size: calc(100vw * (18/ 1520));
        line-height: calc(100vw * (27/ 1520));
      }
      & .sub_title {
        font-size: calc(100vw * (16/ 1520));
        line-height: calc(100vw * (18/ 1520));
        margin-top: calc(100vw * (20/ 1520));
      }
    }
    & .slide_btn {
      width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
      &.btn_prev {
        margin-left: calc(100vw*(-750/1520));
      }
      &.btn_next {
        margin-right: calc(100vw*(-750/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    .slider {
      width: calc(100% + calc(100vw*(40/375)));
      margin-left: calc(100vw*(-20/375));
    }
    .sw-story {
      min-height: calc(100vw * (224 / 375));
      padding: calc(100vw * (60 / 375)) calc(100vw * (15 / 375)) 0 calc(100vw * (15 / 375));

      & .type-chip {
        font-size: calc(100vw * (14 / 375));
        line-height: normal;
        padding: calc(100vw * (4 / 375)) calc(100vw * (30 / 375));
      }
      & .title {
        font-size: calc(100vw * (18/ 375));
        line-height: calc(100vw * (27/ 375));
      }
      & .sub_title {
        font-size: calc(100vw * (16/ 375));
        line-height: calc(100vw * (18/ 375));
        margin-top: calc(100vw * (20/ 375));
      }
    }

    & .slide_btn {display: none;}
  }
`;




export {
  Container
}

export default {
  Container
}