import styled, { css } from 'styled-components';

// img
import BroadcastBg from "resources/image/main/broadcast_bg.jpg";
import MbBroadcastBg from "resources/image/main/mb_main_broadcast_bg.jpg";

const MainBroadcast = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: #000 url(${BroadcastBg}) no-repeat top center /1920px;
  overflow: hidden;
  & .broadcast_inner {
    width: 1500px;
    margin: 0 auto;
    & .broadcast_title {
      color: #fff;
      text-align: center;
      & .dec {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      & .tit {
        font-size: 46px;
        line-height: 60px;
        text-align: center;
        font-weight: 400;
        letter-spacing: -0.5px;
        & span {
          font-weight: 800;
          color: #DA250B;
        }
      }
    }
    & .broadcast_list {
      position: relative;
      width: 1500px;
      margin: 50px auto 0;
      padding: 0 120px;
      & .broadcast_slide {
        width: 100%;
        & img {
          width: 100%; height: 252px;
          object-fit: cover;
        }
        & .broadcast_text {
          & > * {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          }
          & .tit {
            font-size: 24px;
            line-height: 36px;
            font-weight: bold;
            color: #fff;
            margin-top: 30px;
            margin-bottom: 16px;
          }
          & .dec {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #888;
          }
        }
      }

      & .slide_btn {
        position: absolute;
        top: 50%;
        width: 70px; height: 70px;
        transform: translateY(-50%);
        z-index: 1;
        &.btn_prev {
          left: 50%;
          margin-left: -750px;
        }
        &.btn_next {
          right: 50%;
          margin-right: -750px;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(100/1520)) 0;
    & .broadcast_inner {
      width: calc(100vw*(1500/1520));
      & .broadcast_title {
        & .dec {
          font-size: calc(100vw*(20/1520));
          line-height: calc(100vw*(24/1520));
          margin-bottom: calc(100vw*(20/1520));
        }
        & .tit {
          font-size: calc(100vw*(46/1520));
          line-height: calc(100vw*(60/1520));
        }
      }
      & .broadcast_list {
        width: calc(100vw*(1500/1520));
        margin: calc(100vw*(50/1520)) auto 0;
        padding: 0 calc(100vw*(120/1520));
        & .broadcast_slide {
          & img {
            height: calc(100vw*(252/1520));
          }
          & .broadcast_text {
            & .tit {
              font-size: calc(100vw*(24/1520));
              line-height: calc(100vw*(36/1520));
              margin-top: calc(100vw*(30/1520));
              margin-bottom: calc(100vw*(16/1520));
            }
            & .dec {
              font-size: calc(100vw*(16/1520));
              line-height: calc(100vw*(22/1520));
            }
          }
        }

        & .slide_btn {
          width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
          &.btn_prev {
            margin-left: calc(100vw*(-750/1520));
          }
          &.btn_next {
            margin-right: calc(100vw*(-750/1520));
          }
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(72/375)) 0;
    background-image: url(${MbBroadcastBg});
    background-size: 100%;
    & .broadcast_inner {
      width: 100%;
      & .broadcast_title {
        & .dec {
          font-size: calc(100vw*(14/375));
          line-height: calc(100vw*(17/375));
          margin-bottom: calc(100vw*(15/375));
        }
        & .tit {
          font-size: calc(100vw*(30/375));
          line-height: calc(100vw*(39/375));
        }
      }
      & .broadcast_list {
        width: 100%;
        margin: calc(100vw*(50/375)) auto 0;
        padding: 0;
        & .broadcast_slide {
          & img {
            height: calc(100vw*(160/375));
          }
          & .broadcast_text {
            & .tit {
              font-size: calc(100vw*(18/375));
              line-height: calc(100vw*(27/375));
              margin-top: calc(100vw*(15/375));
              margin-bottom: calc(100vw*(10/375));
            }
            & .dec {
              font-size: calc(100vw*(14/375));
              line-height: calc(100vw*(20/375));
            }
          }
        }

        & .slide_btn {
          width: calc(100vw*(35/375)); height: calc(100vw*(35/375));
          &.btn_prev {
            margin-left: calc(100vw*(-180/375));
          }
          &.btn_next {
            margin-right: calc(100vw*(-180/375));
          }
        }
      }
    }
  }
`;




export {
  MainBroadcast
}

export default {
  MainBroadcast
}