import styled, { css } from 'styled-components';

// img
import BnnrImg from "resources/image/main/sub_banner_img.png";
import MbBnnrImg from "resources/image/main/mb_sub_banner_img.jpg";
import QuotesImg from "resources/image/icon/quotes_img_wh.svg";

const MainSubBanner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 80px 0;
  & .sub_inner {
    width: 1260px;
    margin: 0 auto;
    & .text_wrap {
      text-align: center;
      & .tit {
        font-size: 46px;
        line-height: 64px;
        font-weight: 400;
        letter-spacing: -0.5px;
        white-space: nowrap;
        & strong {
          font-weight: 800;
        }
        & span {
          color: #DA250B;
          font-weight: 800;
        }
      }
      & .dec {
        width: 100%;
        font-size: 36px;
        line-height: 43px;
        font-weight: 400;
        margin-top: 20px;
        letter-spacing: -0.5px;
      }
      & .search_box {
        margin-top: 30px;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw*(80/1520)) 0;
    & .sub_inner {
      width: calc(100vw*(1260/1520));
      & .text_wrap {
        & .tit {
          font-size: calc(100vw*(46/1520));
          line-height: calc(100vw*(64/1520));
        }
        & .dec {
          width: 100%;
          font-size: calc(100vw*(36/1520));
          line-height: calc(100vw*(43/1520));
          margin-top: calc(100vw*(20/1520));
        }
        & .search_box {
          margin-top: calc(100vw*(30/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw*(50/375)) 0;
    & .sub_inner {
      width: 100%;
      & .text_wrap {
        & .tit {
          font-size: calc(100vw*(36/375));
          line-height: calc(100vw*(43/375));
          margin-bottom: calc(100vw*(15/375));
        }
        & .dec {
          width: 100%;
          font-size: calc(100vw*(26/375));
          line-height: calc(100vw*(31/375));
          margin-bottom: calc(100vw*(20/375));
        }
        & .search_box {
          margin-top: calc(100vw*(20/375));
          padding: 0 calc(100vw*(20/375));
        }
      }
    }
  }
`;




export {
  MainSubBanner
}

export default {
  MainSubBanner
}