import { useState, useEffect, useRef } from "react";
import Styles from "./styles";
import useSite from "hooks/useSite";
import { IMAGEPATH } from "service/connector";
import { useNavigate } from "react-router-dom";

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import IconQuotes from "resources/image/icon/quotes_img.svg";

function MainReview() {
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [acitve, setAcitve] = useState(false);
  const [tabActive, setTabActive] = useState(0);

  const siteInfo = useSite({
    listOption: {
      board_type: 4,
      content_type: 4,
      type: 1,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setAcitve(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Styles.MainReview ref={elementRef} className={acitve ? "active" : ""}>
      <div className="review_inner">
        <div
          className="tab_contents"
          onClick={() => {
            window.location.href = "/story/review/detail/" + list[tabActive].id;
          }}
        >
          <img src={IMAGEPATH + list[tabActive]?.image} alt="review" />
          <div className="tab_text_box">
            <p className="tit">{list[tabActive]?.title}</p>
            <p className="dec">
              {list[tabActive]?.description_text?.slice(0, 100)}
            </p>
          </div>
        </div>
        <div className="tab_menu">
          <div className="review_tit">
            <h2>의뢰인이 말하는 온강</h2>
          </div>
          <div className="handwriting">
            <img src={IconQuotes} alt="" />
            <p>
              수임을 떠나 정말로 인간으로서 따뜻하게 도와주셔서 <br />{" "}
              감사합니다.
            </p>
            <p className="quot">-의뢰인의 자필 후기 中-</p>
          </div>
          <div className="tab_btn">
            {(list?.slice(0, 3) || [])?.map((item, index) => {
              return (
                <li
                  className={tabActive === index ? "active" : ""}
                  onClick={() => {
                    setTabActive(index);
                  }}
                  key={"tabList" + index}
                >
                  <img src={IMAGEPATH + item.image} alt="" />
                </li>
              );
            })}
          </div>
        </div>
      </div>

      <button
        className="btn_more"
        onClick={() => {
          navigate("/story/review");
        }}
      >
        더보기
        <img src={IconPlus} alt="more view" />
      </button>
    </Styles.MainReview>
  );
}

export default MainReview;
