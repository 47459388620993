import styled, { css } from "styled-components";

const MainMonthly = styled.div`
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: #f5f5f5;
  overflow: hidden;
  & .monthly_inner {
    display: flex;
    align-items: flex-start;
    width: 1260px;
    margin: 0 auto;
    & h2 {
      font-size: 46px;
      line-height: 55px;
      font-weight: 400;
      & b {
        font-weight: 800;
      }
    }
    & .tab_btn {
      position: relative;
      width: 380px;
      margin-top: 50px;
      max-height: 450px;
      overflow: scroll;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        left: 25px;
        display: block;
        width: 368px;
        height: 75px;
        border-radius: 0 0 20px 0;
        background-color: #111;
        transition: 0.3s;
      }
      &::after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        width: 5px;
        height: 80px;
        border-radius: 10px;
        margin-top: 5px;
        background-color: #da250b;
        transition: 0.3s;
      }
      & li {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 75px;
        padding: 0 50px;
        border-radius: 0 0 20px 0;
        font-size: 36px;
        font-weight: 500;
        color: #c4c4c4;
        cursor: pointer;
        z-index: 1;
        &.active {
          color: #fff;
          font-weight: bold;
        }
      }
    }
    & .tab_contents {
      position: relative;
      width: calc(100% - 380px);
      height: 700px;
      padding: 50px 54px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 20px 20px 30px 0 rgba(0, 0, 0, 0.1);
      & .tab_navi {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        & p {
          font-size: 26px;
          font-weight: 600;
          line-height: 31px;
          color: #888;

          &.active {
            color: #111;
          }
        }
      }
      .monthly_list_wrap {
        height: 559px;
        margin-top: 20px;

        .os-scrollbar-handle {
          width: 5px;
          background: #da250b;
        }
      }
      & .monthly_list {
        padding-right: 24px;

        & li {
          display: flex;
          align-items: center;
          position: relative;
          min-height: 70px;
          height: auto;
          padding-left: 30px;
          // padding-right: 280px;
          border-bottom: 1px solid #e4e4e4;
          &:last-child {
            border-bottom: none;
          }
          &:only-child {
            border-bottom: 1px solid #e4e4e4;
          }
          & p {
            width: 65%;
            margin-right: 5%;
            font-size: 18px;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: pre-line;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          & span {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 30%;
            min-height: 70px;
            font-size: 20px;
            line-height: 70px;
            text-align: center;
            font-weight: bold;
            padding: 0 15px;
            background-color: #da250b;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-bottom: 1px solid #efefef;

            position: unset;
            white-space: pre-line;
            line-height: unset;
            width: 30%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding: calc(100vw * (100 / 1520)) 0;
    & .monthly_inner {
      width: calc(100vw * (1260 / 1520));
      & h2 {
        font-size: calc(100vw * (46 / 1520));
        line-height: calc(100vw * (55 / 1520));
      }
      & .tab_btn {
        width: calc(100vw * (380 / 1520));
        max-height: calc(100vw * (450 / 1520));
        margin-top: calc(100vw * (50 / 1520));
        &::before {
          left: calc(100vw * (25 / 1520));
          width: calc(100vw * (368 / 1520));
          height: calc(100vw * (75 / 1520));
          border-radius: 0 0 calc(100vw * (20 / 1520)) 0;
        }
        &::after {
          width: calc(100vw * (5 / 1520));
          height: calc(100vw * (80 / 1520));
          border-radius: calc(100vw * (10 / 1520));
          margin-top: calc(100vw * (5 / 1520));
        }
        & li {
          height: calc(100vw * (75 / 1520));
          padding: 0 calc(100vw * (50 / 1520));
          border-radius: 0 0 calc(100vw * (20 / 1520)) 0;
          font-size: calc(100vw * (36 / 1520));
        }
      }
      & .tab_contents {
        width: calc(100% - calc(100vw * (380 / 1520)));
        height: calc(100vw * (700 / 1520));
        padding: calc(100vw * (50 / 1520)) calc(100vw * (50 / 1520))
          calc(100vw * (50 / 1520)) calc(100vw * (54 / 1520));
        border-radius: calc(100vw * (10 / 1520));
        box-shadow: calc(100vw * (20 / 1520)) calc(100vw * (20 / 1520))
          calc(100vw * (30 / 1520)) 0 rgba(0, 0, 0, 0.1);
        & .tab_navi {
          gap: calc(100vw * (50 / 1520));
          & p {
            font-size: calc(100vw * (26 / 1520));
            line-height: calc(100vw * (31 / 1520));
          }
        }
        .monthly_list_wrap {
          height: calc(100vw * (559 / 1520));
          margin-top: calc(100vw * (20 / 1520));

          .os-scrollbar-handle {
            width: calc(100vw * (5 / 1520));
          }
        }
        & .monthly_list {
          padding-right: calc(100vw * (24 / 1520));
          & li {
            height: calc(100vw * (70 / 1520));
            padding-left: calc(100vw * (30 / 1520));
            // padding-right: calc(100vw*(280/1520));
            & p {
              font-size: calc(100vw * (18 / 1520));
            }
            & span {
              width: calc(100vw * (240 / 1520));
              height: calc(100vw * (70 / 1520));
              font-size: calc(100vw * (20 / 1520));
              padding: 0 calc(100vw * (15 / 1520));
              // line-height: calc(100vw*(70/1520));
            }
          }
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding: calc(100vw * (50 / 375)) 0;
    & .monthly_inner {
      flex-direction: column;
      width: calc(100vw * (335 / 375));
      & .tab_menu {
        width: 100%;
        & h2 {
          width: 100%;
          font-size: calc(100vw * (30 / 375));
          line-height: calc(100vw * (36 / 375));
          text-align: center;
        }
        & .tab_btn {
          display: none;
        }
      }
      & .tab_contents {
        width: 100%;
        height: calc(100vw * (390 / 375));
        padding: calc(100vw * (30 / 375)) calc(100vw * (15 / 375))
          calc(100vw * (30 / 375)) calc(100vw * (15 / 375));
        border-radius: calc(100vw * (10 / 375));
        margin-top: calc(100vw * (30 / 375));
        box-shadow: calc(100vw * (20 / 375)) calc(100vw * (20 / 375))
          calc(100vw * (30 / 375)) 0 rgba(0, 0, 0, 0.1);
        & .tab_navi {
          gap: calc(100vw * (50 / 375));
          & p {
            font-size: calc(100vw * (20 / 375));
            line-height: calc(100vw * (24 / 375));
          }
        }
        .monthly_list_wrap {
          height: calc(100vw * (280 / 375));
          margin-top: calc(100vw * (20 / 375));

          .os-scrollbar-handle {
            width: calc(100vw * (5 / 375));
          }
        }
        & .monthly_list {
          padding-right: calc(100vw * (18 / 375));
          & li {
            min-height: calc(100vw * (35 / 375));
            height: auto;
            padding-left: calc(100vw * (0 / 375));
            padding-right: calc(100vw * (0 / 375));
            & p {
              width: 65%;
              margin-right: 5%;
              font-size: calc(100vw * (14 / 375));
            }
            & span {
              position: unset;
              white-space: pre-line;
              width: calc(100vw * (90 / 375));
              min-height: calc(100vw * (35 / 375));
              font-size: calc(100vw * (15 / 375));
              line-height: calc(100vw * (35 / 375));
              padding: 0 calc(100vw * (10 / 375));
              line-height: unset;
              width: 30%;
              height: auto;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;

const TabBtn = styled.div`
  &::before {
    top: ${(props) => props.top}px;
  }
  &::after {
    top: ${(props) => props.top}px;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    &::before {
      top: calc(100vw * (${(props) => props.top} / 1520));
    }
    &::after {
      top: calc(100vw * (${(props) => props.top} / 1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    &::before {
      top: calc(100vw * (${(props) => props.top} / 375));
    }
  }
`;

export { MainMonthly, TabBtn };

export default {
  MainMonthly,
  TabBtn,
};
