import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.connectFetchController( `question?${utils.etc.getFilterStr(data)}`,"GET");
};

const doQuestion = async (data) => {
  return await connector.jsonConnector(
    `question`,
    "POST",
    data
  );
};

export { getList, doQuestion };

export default { getList, doQuestion };

