import styled from "styled-components";

const SearchList = styled.div`
  position: relative;
  width: 1260px;
  padding-top: 40px;
  padding-bottom: 100px;
  margin: 0 auto;
  & .search_wrap {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 70px;
    & .search_list {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      & .search_row {
        display: flex;
        gap: 20px;
        width: 100%;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(196, 196, 196, 0.2);
        cursor: pointer;
        & .search_img {
          width: 102px;
          height: 145px;
          object-fit: cover;
        }
        & .search_content {
          width: calc(100% - 122px);
          padding-right: 128px;
          & .tag {
            display: inline-block;
            padding: 6px 30px;
            font-size: 18px;
            line-height: 21px;
            font-weight: 600;
            color: #fff;
            background-color: #da250b;
            margin-bottom: 14px;
          }
          & .title_box {
            display: flex;
            align-items: center;
            gap: 20px;
            & .search_tit {
              max-width:74%;
              font-size: 32px;
              line-height: 38px;
              font-weight: bold;
            }
            & .date_box {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              color: #888;
              & .group {
                display: flex;
                align-items: center;
                gap: 10px;
                & b {
                  font-weight: bold;
                }
                &::before {
                  content: "";
                  display: block;
                  width: 1px;
                  height: 14px;
                  background-color: #e4e4e4;
                }
              }
            }
          }
          & .dec {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: #888;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
      & .none_list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 448px;
        font-size: 18px;
        color: #c4c4c4;
      }
    }
  }

  & .sort-tab {
    margin-top: 40px;
    &__list {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
    }
    &__item {
      flex: 1;

      & .sort-btn {
        width: 170px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre;
        font-size: 18px;
        font-weight: 500;
        color: #888;
        background: #f5f5f5;
        border-radius: 5px;
        position: relative;

        /* &::before {
          content: "";
          width: 7px;
          height: 7px;
          background: #fff;
          border-radius: 50%;
          margin-right: 10px;
          display: none;
        } 
        &.active::before {
          display: block;
        }*/
        &.active {
          background: #da250b;
          color: #fff;
        }
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw * (1260 / 1520));
    padding-top: calc(100vw * (40 / 1520));
    padding-bottom: calc(100vw * (100 / 1520));
    & .search_wrap {
      margin-top: calc(100vw * (40 / 1520));
      margin-bottom: calc(100vw * (70 / 1520));
      & .search_list {
        gap: calc(100vw * (30 / 1520));
        & .search_row {
          gap: calc(100vw * (20 / 1520));
          padding-bottom: calc(100vw * (30 / 1520));
          & .search_img {
            width: calc(100vw * (102 / 1520));
            height: calc(100vw * (145 / 1520));
          }
          & .search_content {
            width: calc(100% - calc(100vw * (122 / 1520)));
            padding-right: calc(100vw * (128 / 1520));
            & .tag {
              padding: calc(100vw * (6 / 1520)) calc(100vw * (30 / 1520));
              font-size: calc(100vw * (18 / 1520));
              line-height: calc(100vw * (21 / 1520));
              margin-bottom: calc(100vw * (14 / 1520));
            }
            & .title_box {
              gap: calc(100vw * (20 / 1520));
              & .search_tit {
                max-width:100%;
                font-size: calc(100vw * (32 / 1520));
                line-height: calc(100vw * (38 / 1520));
              }
              & .date_box {
                gap: calc(100vw * (10 / 1520));
                font-size: calc(100vw * (14 / 1520));
                line-height: calc(100vw * (17 / 1520));
                & .group {
                  gap: calc(100vw * (10 / 1520));
                  &::before {
                    width: calc(100vw * (1 / 1520));
                    height: calc(100vw * (14 / 1520));
                  }
                }
              }
            }
            & .dec {
              font-size: calc(100vw * (14 / 1520));
              line-height: calc(100vw * (20 / 1520));
              margin-top: calc(100vw * (20 / 1520));
            }
          }
        }
        & .none_list {
          height: calc(100vw * (448 / 1520));
          font-size: calc(100vw * (18 / 1520));
        }
      }
    }

    & .sort-tab {
      margin-top: calc(100vw * (40 / 1520));
      &__list {
        gap: calc(100vw * (10 / 1520));
      }
      &__item {
        & .sort-btn {
          width: calc(100vw * (170 / 1520));
          height: calc(100vw * (56 / 1520));
          font-size: calc(100vw * (18 / 1520));
          color: #888;
          /* &::before {
            content: "";
            width: calc(100vw * (7 / 1520));
            height: calc(100vw * (7 / 1520));
            margin-right: calc(100vw * (10 / 1520));
          } */
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: 100%;
    padding: calc(100vw * (30 / 375)) calc(100vw * (20 / 375)) calc(100vw * (100 / 375));
    & .search_wrap {
      margin-top: calc(100vw * (30 / 375));
      margin-bottom: calc(100vw * (40 / 375));
      & .search_list {
        gap: calc(100vw * (30 / 375));
        & .search_row {
          gap: calc(100vw * (20 / 375));
          padding-bottom: calc(100vw * (30 / 375));
          border-bottom-width: 2px;
          & .search_img {
            width: calc(100vw * (102 / 375));
            height: calc(100vw * (145 / 375));
          }
          & .search_content {
            width: calc(100% - calc(100vw * (112 / 375)));
            padding-right: 0;
            & .tag {
              padding: calc(100vw * (5 / 375)) calc(100vw * (20 / 375));
              font-size: calc(100vw * (14 / 375));
              line-height: calc(100vw * (17 / 375));
              margin-bottom: calc(100vw * (10 / 375));
            }
            & .title_box {
              flex-direction: column;
              align-items: flex-start;
              gap: calc(100vw * (10 / 375));
              & .search_tit {
                max-width:100%;
                font-size: calc(100vw * (20 / 375));
                line-height: calc(100vw * (26 / 375));
              }
              & .date_box {
                gap: calc(100vw * (10 / 375));
                font-size: calc(100vw * (14 / 375));
                line-height: calc(100vw * (20 / 375));
                & .group {
                  gap: calc(100vw * (10 / 375));
                  &::before {
                    width: calc(100vw * (1 / 375));
                    height: calc(100vw * (14 / 375));
                  }
                }
              }
            }
            & .dec {
              font-size: calc(100vw * (14 / 375));
              line-height: calc(100vw * (20 / 375));
              margin-top: calc(100vw * (10 / 375));
            }
          }
        }
        & .none_list {
          height: calc(100vw * (448 / 375));
          font-size: calc(100vw * (18 / 375));
        }
      }
    }

    & .sort-tab {
      margin-top: calc(100vw * (30 / 375));
      &__list {
        gap: calc(100vw * (10 / 375));
        flex-wrap: wrap;
        justify-content: center;
      }
      &__item {
        flex: 0;
        & .sort-btn {
          width: calc(100vw * (120 / 375));
          height: calc(100vw * (44 / 375));
          font-size: calc(100vw * (16 / 375));
          white-space: break-spaces;
          word-wrap: break-word;
          word-break: keep-all;
          /* &::before {
            content: "";
            width: calc(100vw * (7 / 375));
            height: calc(100vw * (7 / 375));
            margin-right: calc(100vw * (10 / 375));
          } */
        }
      }
    }
  }
`;

export { SearchList };

export default {
  SearchList,
};
