import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import ExamplePage from "./ExamplePage";
import ExampleDetailPage from "./ExampleDetailPage";
import IntroPage from "./IntroPage";
import IntroExertDetailPage from "./IntroExertDetailPage";
import IntroNewsDetailPage from "./IntroNewsDetailPage";
import StoryPage from "./StoryPage";
import StoryReviewDetailPage from "./StoryReviewDetailPage";
import StoryColumnDetailPage from "./StoryColumnDetailPage";
import SituationPage from "./SituationPage";
import CounselPage from "./CounselPage";
import SearchPage from "./SearchPage";

export default {
  ModalPage,
  MainPage,
  ExamplePage,
  ExampleDetailPage,
  IntroPage,
  IntroExertDetailPage,
  IntroNewsDetailPage,
  StoryPage,
  StoryReviewDetailPage,
  CounselPage,
  SearchPage,
  SituationPage,
  StoryColumnDetailPage,
};

export { ModalPage,
  MainPage,
  ExamplePage,
  ExampleDetailPage,
  CounselPage,
  SearchPage,
};
