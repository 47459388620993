import styled from "styled-components";

const Tab = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
  & li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 170px;
    height: 56px;
    font-size: 18px;
    text-align: center;
    line-height: 21px;
    font-weight: 500;
    border-radius: 5px;
    color: #888;
    background-color: #f5f5f5;
    white-space: pre-line;
    word-break: keep-all;
    cursor: pointer;
    &::before {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #fff;
    }
    &.active {
      background-color: #da250b;
      color: #fff;
      &:first-child::before {
        content: "";
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    gap: calc(100vw * (10 / 1520));
    margin-top: calc(100vw * (50 / 1520));
    & li {
      gap: calc(100vw * (10 / 1520));
      width: calc(100vw * (170 / 1520));
      height: calc(100vw * (56 / 1520));
      font-size: calc(100vw * (18 / 1520));
      line-height: calc(100vw * (21 / 1520));
      border-radius: calc(100vw * (5 / 1520));
      &::before {
        width: calc(100vw * (7 / 1520));
        height: calc(100vw * (7 / 1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    gap: calc(100vw * (10 / 375));
    margin-top: calc(100vw * (30 / 375));
    & li {
      gap: calc(100vw * (10 / 375));
      width: calc(100vw * (120 / 375));
      height: calc(100vw * (44 / 375));
      font-size: calc(100vw * (16 / 375));
      line-height: calc(100vw * (19 / 375));
      border-radius: calc(100vw * (5 / 375));

      &:first-child {
        width: calc(100vw * (250 / 375));
      }
      &::before {
        width: calc(100vw * (7 / 375));
        height: calc(100vw * (7 / 375));
      }
    }
  }
`;

export { Tab };

export default { Tab };
