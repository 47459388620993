import { useState, useEffect } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PC, Mobile } from "hooks/useMediaQuery";
import { Tab } from "components/layout";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";
import utils from "utils";
import { IMAGEPATH } from "service/connector";

// img
import IconNext from "resources/image/icon/icon_next.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import IconScale from "resources/image/icon/icon_scale.svg";
import HoverImg from "resources/image/slide/hover_img.png";

function ExampleSlide({ tab, list }) {
  const [swiper, setSwiper] = useState(null);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      if (swiper) {
        swiper.update(); // Swiper 수동 업데이트
      }
    }, 100);
    window.addEventListener("resize", handleResize);
  }, []);

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };
  useEffect(() => {
    SwiperCore?.use([Autoplay]);
  }, []);

  return (
    <Styles.ExampleSlide>
      {tab && <Tab />}
      <div className="swiper_wrap">
        <PC>
          <Swiper
            modules={[Autoplay]}
            className="slider"
            slidesPerView={4}
            loop={true}
            // loopFillGroupWithBlank={true}
            speed={1500}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            spaceBetween={20}
            slidesPerGroup={2}
            onSwiper={(e) => {
              setSwiper(e);
            }}
          >
            {list?.map((item, index) => {
              return (
                <SwiperSlide key={"exampleList" + index}>
                  <div className="example_slide">
                    <div className="example_inner">
                      <div className="example_tit">
                        <img src={IconScale} alt={item.type} />
                        <span>{item.subtitle}</span>
                      </div>
                      <div className="example_text">
                        <p className="tit">{item.title}</p>
                        <p className="dec">
                          {item.description_text?.slice(0, 100)}
                        </p>
                        <span className="date">
                          종결일{" "}
                          <span>
                            {utils.date.getDateType(item.ended_at, "-")}
                          </span>
                        </span>
                      </div>
                      <img
                        src={IMAGEPATH + item.image}
                        className="hover_img"
                        alt={item.type}
                      />
                    </div>
                    <button
                      className="btn_more"
                      onClick={() => {
                        window.location.href = `/example/detail/${item.id}`;
                      }}
                    >
                      자세히 보기
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </PC>
        <Mobile>
          <Swiper
            modules={[Autoplay]}
            className="slider"
            slidesPerView={1.14}
            loop={true}
            loopFillGroupWithBlank={true}
            speed={1500}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            spaceBetween={15}
            centeredSlides={true}
            onSwiper={(e) => {
              setSwiper(e);
            }}
          >
            {list?.map((item, index) => {
              return (
                <SwiperSlide key={"exampleList" + index}>
                  <div className="example_slide">
                    <div className="example_inner">
                      <div className="example_tit">
                        <img src={IconScale} alt={item.type} />
                        <span>{item.subtitle}</span>
                      </div>
                      <div className="example_text">
                        <p className="tit">{item.title}</p>
                        <p className="dec">
                          {item.description_text?.slice(0, 100)}
                        </p>
                        <span className="date">
                          종결일{" "}
                          <span>
                            {utils.date.getDateType(item.ended_at, "-")}
                          </span>
                        </span>
                      </div>
                      <img
                        src={IMAGEPATH + item.image}
                        className="hover_img"
                        alt={item.type}
                      />
                    </div>
                    <button
                      className="btn_more"
                      onClick={() => {
                        window.location.href = `/example/detail/${item.id}`;
                      }}
                    >
                      자세히 보기
                    </button>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Mobile>
      </div>
      {list?.length > 1 && (
        <>
          <button className="slide_btn btn_prev" onClick={handlePrev}>
            <img src={IconPrev} alt="슬라이드 이전" />
          </button>
          <button className="slide_btn btn_next" onClick={handleNext}>
            <img src={IconNext} alt="슬라이드 다음" />
          </button>
        </>
      )}
    </Styles.ExampleSlide>
  );
}

export { ExampleSlide };

export default ExampleSlide;
