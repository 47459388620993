import connector from "service/connector";
import utils from "utils";

const getList = async (data) => {
  return await connector.connectFetchController(
    `site?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

const getCategoryList = async (data) => {
  return await connector.connectFetchController(
    `site/category?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

const getGroupDateList = async (data) => {
  return await connector.connectFetchController(
    `site/group_date?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

const getGroupList = async (data) => {
  return await connector.connectFetchController(
    `site/group_list?${utils.etc.getFilterStr(data)}`,
    "GET"
  );
};

export { getList, getGroupDateList,getCategoryList,getGroupList };

export default {
  getList,
  getGroupDateList,
  getCategoryList,
  getGroupList
};
