import { useState, useEffect } from "react";
import { ExampleSlide } from "components/layout";
import Styles from './styles';

// img
import IconPlus from "resources/image/icon/icon_plus_bk.svg";
import { useLocation } from "react-router-dom";
import utils from "utils";
import { useBoard } from "hooks";

function SubExample() {
  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      // parent_category_info_id: "32",
      category_info_id: "",
      type: 2,
      recordSize: 10,
      hold_yn :"Y",
    };
    setFilterInfo({ ...f });
  }, []);

  const boardInfo = useBoard({
    listOption: filterInfo
  });
  const list = boardInfo.listQuery?.data?.list || [];

  return (
    <Styles.SubExample>
      <div className="sub_example_inner">
        <h2>이달의 주요 성공사례</h2>
        <ExampleSlide list={list} />
      </div>
    </Styles.SubExample>
  );
}

export default SubExample;