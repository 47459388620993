import styled from "styled-components";

const MainExample = styled.div`
  position: relative;
  width: 1500px;
  padding: 100px 0;
  margin: 0 auto;
  & .slide_box {
    padding-bottom: 80px;
    border-bottom: 1px solid #C4C4C4;
    & h2 {
      font-size: 56px;
      font-weight: 400;
      letter-spacing: -0.5px;
      text-align: center;
      & strong {
        font-weight: 800;
      }
    }
    & p {
      font-size: 26px;
      font-weight: 500;
      line-height: 36px;
      text-align: center;
      margin-top: 20px;
      font-weight: 600;
      & span {
        color: #da250b;
      }
    }
    & > span {
      display: block;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      margin-top: 20px;
      color: #888;
    }
    & .slide_list {
      position: relative;
      padding: 0 120px;
      & .swiper {
        padding: 50px 0;
      }
      & .swiper-slide {
        box-shadow: 10px 10px 40px rgba(90, 90, 90, 0.25);
      }
      & .cer_slide {
        width: 100%;
        & img {
          width: 100%; height: auto;
          margin: 0 auto;
        }
      }
      & .slide_btn {
        position: absolute;
        top: 50%;
        width: 70px; height: 70px;
        z-index: 1;
        transform: translateY(-50%);
        cursor: pointer;
        &.btn_prev {
          left: 0;
        }
        &.btn_next {
          right: 0;
        }
      }
    }
  }
  & .example_box {
    position: relative;
    padding-top: 80px;
    & h2 {
      font-size: 56px;
      font-weight: 800;
      letter-spacing: -0.5px;
      text-align: center;
    }
    & p {
      font-size: 26px;
      font-weight: 500;
      line-height: 36px;
      text-align: center;
      margin-top: 20px;
      & span {
        color: #da250b;
      }
    }
    & > .btn_more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 154px;
      height: 45px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      padding: 0 30px;
      border-radius: 30px;
      border: 1px solid #111;
      transition: 0.5s;
      z-index: 1;
      & img {
        width: 18px;
        height: 18px;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        & img {
          animation: rotate 0.8s ease-in-out forwards;
        }
      }
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    width: calc(100vw*(1500/1520));
    padding: calc(100vw*(100/1520)) 0;
    & .slide_box {
      padding-bottom: calc(100vw*(80/1520));
      & h2 {
        font-size: calc(100vw*(56/1520));
      }
      & p {
        font-size: calc(100vw*(26/1520));
        line-height: calc(100vw*(36/1520));
        margin-top: calc(100vw*(20/1520));
      }
      & > span {
        font-size: calc(100vw*(18/1520));
        line-height: calc(100vw*(25/1520));
        margin-top: calc(100vw*(20/1520));
      }
      & .slide_list {
        padding: 0 calc(100vw*(120/1520));
        & .swiper {
          padding: calc(100vw*(50/1520)) 0;
        }
        & .swiper-slide {
          box-shadow: calc(100vw*(10/1520)) calc(100vw*(10/1520)) calc(100vw*(40/1520)) rgba(90, 90, 90, 0.25);
        }
        & .slide_btn {
          width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
        }
      }
    }
    & .example_box {
      padding-top: calc(100vw*(80/1520));
      & h2 {
        font-size: calc(100vw*(56/1520));
      }
      & p {
        font-size: calc(100vw*(26/1520));
        font-weight: 500;
        line-height: calc(100vw*(36/1520));
        margin-top: calc(100vw*(20/1520));
      }
      & > .btn_more {
        width: calc(100vw*(154/1520)); height: calc(100vw*(45/1520));
        font-size: calc(100vw*(18/1520));
        padding: 0 calc(100vw*(30/1520));
        border-radius: calc(100vw*(30/1520));
        & img {
          width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));
        }
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    width: 100%;
    padding: calc(100vw*(80/375)) 0;
    & .slide_box {
      padding-bottom: calc(100vw*(20/375));
      & h2 {
        font-size: calc(100vw*(36/375));
        line-height: calc(100vw*(47/375));
      }
      & p {
        font-size: calc(100vw*(18/375));
        line-height: calc(100vw*(25/375));
        margin-top: calc(100vw*(15/375));
        font-weight: 600;
      }
      & > span {
        font-size: calc(100vw*(14/375));
        line-height: calc(100vw*(20/375));
        margin-top: calc(100vw*(15/375));
      }
      & .slide_list {
        padding: 0;
        & .swiper {
          padding: calc(100vw*(50/375)) 0;
        }
        & .swiper-slide {
          box-shadow: calc(100vw*(10/375)) calc(100vw*(10/375)) calc(100vw*(40/375)) rgba(90, 90, 90, 0.25);
        }
        & .slide_btn {
          display: none;
        }
      }
    }
    & .example_box {
      padding-top: calc(100vw*(50/375));
      & h2 {
        font-size: calc(100vw*(30/375));
      }
      & p {
        font-size: calc(100vw*(18/375));
        line-height: calc(100vw*(25/375));
        margin-top: calc(100vw*(20/375));
      }
      & > .btn_more {
        width: calc(100vw*(154/375)); height: calc(100vw*(45/375));
        font-size: calc(100vw*(18/375));
        padding: 0 calc(100vw*(30/375));
        border-radius: calc(100vw*(30/375));
        & img {
          width: calc(100vw*(18/375)); height: calc(100vw*(18/375));
        }
      }
    }
  }
`;

export { MainExample };

export default {
  MainExample,
};
