import React from "react";

import FloatHeading from "components/layout/FloatHeading";
import { EX_SWIPER_LIST, IMG, INTRO_HEADING_DATA } from "constant";
import ArticleHeading from "components/layout/ArticleHeading";
import ImgBox from "components/layout/ImgBox";
import SwiperWrap from "components/layout/SwiperWrap";
import { useParams } from "react-router-dom";
import { useBoard } from "hooks";
import utils from "utils";
import { IMAGEPATH } from "service/connector";

const IntroNewsDetailPage = () => {
  const param = useParams();
  const boardInfo = useBoard({
    detailOption: { id : param.id },
    listOption: {
      page: 1,
      pageSize: 5,
      parent_category_info_id: "",
      category_info_id: "",
      hold_yn:"Y",
      type: 5,
      recordSize: 10,
    }
  });

  const bestList = boardInfo.listQuery?.data?.list || [];
  const detail = boardInfo.detailQuery?.data?.data || {};

  return (
    <div className="intro page">
      <div className="inner">
        <FloatHeading data={INTRO_HEADING_DATA} />

        <section className="section export edit full ">
          <div className="expert-editor-wrap">
            <div className="editor-head">
              <div className="head-inner">
                <div className="float-type">{detail.keyword}</div>
                <div className="head-box">
                  <div className="text-wrap">
                    <p className="title">{detail.title}</p>
                    <p className="sub-title">{utils.date.getDateType(detail.created_at)}</p>
                  </div>
                  <div className="img-box">
                    <ImgBox src={IMAGEPATH + detail.image} />
                  </div>
                </div>
              </div>
            </div>
            <div  className="editor-box editor ck-content" style={{minHeight:400, }} dangerouslySetInnerHTML={{__html : detail?.description}}></div>
          </div>

          <article className="other-list">
            <ArticleHeading title={"온강 주요 소식"} />
            <SwiperWrap list={bestList || EX_SWIPER_LIST} perView={2.7} perGroup={1} link={"/intro/news/detail/"} />
            <div className="btn-box">
              <button type="button" className="btn" onClick={()=>{ window.history.back(); }}>
                목록으로 돌아가기
              </button>
            </div>
          </article>
        </section>
      </div>
    </div>
  );
};

export default IntroNewsDetailPage;
