import styled from "styled-components";
import { ExampleSlide } from "../ExampleSlide/styles";

const SwiperWrapStyle = styled(ExampleSlide)`
  & .sw-container {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;

    & .swiper_wrap {
      & .swiper {
        margin: 30px 120px;
        max-width: 1260px;
        padding: 0;
      }

      & .swiper-slide {
        cursor: pointer;
        & .img-box-container {
          height: 251px;
          .img-box {
            height: 100%;
          }
          img {
            height: 100%;
            object-fit: cover;
          }
          margin-bottom: 30px;
        }
        &:hover {
          .img-box {
            position: relative;
            &::after {
              content: "";
              width: 100%;
              height: 100%;
              background-color: transparent;
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              border: 5px solid #da250b;
              box-sizing: border-box;
            }
          }
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 16px;
      min-height: 72px;
      line-height: 150%;
    }
    .sub-title {
      font-size: 16px;
      color: #888;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .slide_btn {
      bottom: initial;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  @media (max-width: 1520px) {
    & .sw-container {
      max-width: calc(100vw * (1500 / 1520));

      & .swiper_wrap {
        & .swiper {
          margin: calc(100vw * (50 / 1520)) calc(100vw * (120 / 1520));
          max-width: calc(100vw * (1260 / 1520));

          & .swiper-slide {
            & .img-box-container {
              height: calc(100vw * (251 / 1520));
              margin-bottom: calc(100vw * (30 / 1520));
            }
          }
        }
      }

      .title {
        font-size: calc(100vw * (24 / 1520));
        margin-bottom: calc(100vw * (16 / 1520));
        min-height: calc(100vw * (72 / 1520));
        line-height: 150%;
      }
      .sub-title {
        font-size: calc(100vw * (16 / 1520));
      }
    }
  }

  @media (max-width: 1024px) {
    & .sw-container {
      max-width: calc(100vw * (1500 / 375));

      & .swiper_wrap {
        & .swiper {
          margin: calc(100vw * (15 / 375)) calc(100vw * (0 / 375)) calc(100vw * (30 / 375)) 0;
          max-width: calc(100vw * (256 / 375));

          & .swiper-slide {
            & .img-box-container {
              height: calc(100vw * (161 / 375));
              margin-bottom: calc(100vw * (15 / 375));
            }
          }
        }
      }

      .title {
        font-size: calc(100vw * (18 / 375));
        margin-bottom: calc(100vw * (10 / 375));
        min-height: calc(100vw * (54 / 375));
        line-height: 150%;
      }
      .sub-title {
        font-size: calc(100vw * (14 / 375));
      }
    }

    & .slide_btn {
      width: calc(100vw * (34 / 375));
      display: block;
      left: initial;
      &.btn_prev {
        left: calc(100vw * (20 / 375));
      }
      &.btn_next {
        left: initial;
        right: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { SwiperWrapStyle };

export default { SwiperWrapStyle };
