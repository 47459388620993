import styled, { css } from 'styled-components';

const Container = styled.div`
  position: relative;
  & .column_slide {
    width: 100%;
    & .tag {
      position: relative;
      width: 100%; height: 252px;
      & img {
        width: 100%; height: 100%;
        object-fit: cover;
      }
      & i {
        display: block;
        position: absolute;
        left: 0; top: 0;
        width: 132px;
        height: 32px;
        line-height: 32px;
        padding: 0 26px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: #DA250B;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; 
      }
    }
    & .column_text {
      & > * {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      }
      & .tit {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 16px;
      }
      & .dec {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #888;
      }
    }
  }

  & .slide_btn {
    position: absolute;
    top: 50%;
    width: 70px; height: 70px;
    transform: translateY(-50%);
    z-index: 1;
    &.btn_prev {
      left: 50%;
      margin-left: -750px;
    }
    &.btn_next {
      right: 50%;
      margin-right: -750px;
    }
  }
    

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .column_slide {
      & .tag {
        height: calc(100vw*(252/1520));
        & i {
          width: calc(100vw*(132/1520));
          height: calc(100vw*(32/1520));
          line-height: calc(100vw*(32/1520));
          padding: 0 calc(100vw*(26/1520));
          font-size: calc(100vw*(18/1520));
        }
      }
      & .column_text {
        & .tit {
          font-size: calc(100vw*(24/1520));
          line-height: calc(100vw*(36/1520));
          margin-top: calc(100vw*(30/1520));
          margin-bottom: calc(100vw*(16/1520));
        }
        & .dec {
          font-size: calc(100vw*(16/1520));
          line-height: calc(100vw*(22/1520));
        }
      }
    }
    & .slide_btn {
      width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
      &.btn_prev {
        margin-left: calc(100vw*(-750/1520));
      }
      &.btn_next {
        margin-right: calc(100vw*(-750/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .column_slide {
      & .tag {
        height: calc(100vw*(160/375));
        & i {
          width: calc(100vw*(100/375));
          height: calc(100vw*(26/375));
          line-height: calc(100vw*(26/375));
          padding: 0 calc(100vw*(18/375));
          font-size: calc(100vw*(14/375));
        }
      }
      & .column_text {
        & .tit {
          font-size: calc(100vw*(18/375));
          line-height: calc(100vw*(27/375));
          margin-top: calc(100vw*(15/375));
          margin-bottom: calc(100vw*(10/375));
          letter-spacing: -0.7px;
        }
        & .dec {
          font-size: calc(100vw*(14/375));
          line-height: calc(100vw*(20/375));
        }
      }
    }

    & .slide_btn {display: none;}
  }
`;




export {
  Container
}

export default {
  Container
}