import button from './button';
import input from "./input"


export default {
    button,
    input
}

export {
    button,
    input
}