import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > button:first-child {
    margin-right: 20px;
  }
  & > button:last-child {
    margin-left: 20px;
  }

  & .iconDoubleArrow, .iconArrow {
    width: 24px;
    height: 24px;
  }

  & .paginationList {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 30px;

    & button {
      position: relative;
      width: 22px;
      height: 22px;
      font-size: 18px;
      font-weight: 500;

      &.on {
        color: #DA250B;
      }
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & > button:first-child {
      margin-right: calc(100vw*(20/1520));
    }
    & > button:last-child {
      margin-left: calc(100vw*(20/1520));
    }

    & .iconDoubleArrow, .iconArrow {
      width: calc(100vw*(24/1520));
      height: calc(100vw*(24/1520));
    }

    & .paginationList {
      gap: calc(100vw*(15/1520));
      margin: 0 calc(100vw*(30/1520));

      & button {
        width: calc(100vw*(22/1520));
        height: calc(100vw*(22/1520));
        font-size: calc(100vw*(18/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & > button:first-child {
      margin-right: calc(100vw*(10/375));
    }
    & > button:last-child {
      margin-left: calc(100vw*(10/375));
    }

    & .iconDoubleArrow, .iconArrow {
      width: calc(100vw*(20/375));
      height: calc(100vw*(20/375));
    }

    & .paginationList {
      gap: calc(100vw*(10/375));
      margin: 0 calc(100vw*(10/375));

      & button {
        width: calc(100vw*(22/375));
        height: calc(100vw*(22/375));
        font-size: calc(100vw*(16/375));
      }
    }
  }
`;

export { Container };

export default { Container };
