import { useState, useEffect, useRef } from "react";
import Styles from "./styles";
import { PC, Mobile } from "hooks/useMediaQuery";
import { useModals } from "hooks";

// img
import BnnrImg from "resources/image/main/main_banner_img.png";
import MbBnnrImg from "resources/image/main/mb_main_banner_img.png";
import TitleImg from "resources/image/main/title_img.png";
import TitleImgMb from "resources/image/main/title_img_mb.png";

function MainBanner() {
  const modalOption = useModals();

  const [acitve, setAcitve] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setAcitve(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <Styles.MainBanner ref={elementRef} className={acitve ? "active" : ""}>
      <div className="bnnr_inner">
        <div className="bnnr_tit">
          <div className="line">
            <i className="top"></i>
            <i className="left"></i>
            <i className="right"></i>
          </div>
          <h2>법무법인 온강</h2>
          <p>
            <b>전직검사</b>들이 함께하는
          </p>
        </div>
      </div>
      <PC>
        <img src={BnnrImg} className="bnnr_img" alt="법무법인 온강" />
        <div className="title_img_wrap">
          <img src={TitleImg} className="title_img" alt="법무법인 온강" />
        </div>
      </PC>
      <Mobile>
        <img src={MbBnnrImg} className="bnnr_img" alt="법무법인 온강" />
        <div className="title_img_wrap">
          <img src={TitleImgMb} className="title_img" alt="법무법인 온강" />
        </div>
      </Mobile>
    </Styles.MainBanner>
  );
}

export default MainBanner;
