import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages from "pages";
import utils from "utils";

export default function CustomRoute() {
  let location = useLocation();
  React.useEffect(() => {

    let param = utils.etc.getSearchParam();
    if (!param.disableScrollTop && !param.focus) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      document.body.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    if (param.focus) {
      try {
        setTimeout(() => {
          document.getElementById(utils.etc.getSearchParam().focus)?.scrollIntoView({ block: param.focus_block ? param.focus_block : "start", behavior: 'smooth' });
        }, 300);
      } catch (e) {

      }
    }

    if(window.location.pathname.includes("%BC%BA%B9%FC%C1&CB")){
      window.location.replace("/성범죄");
    }else if(window.location.pathname.includes("php") || document.getElementsByClassName("container-inner")[0].children.length == 2){
      window.location.replace("/");
    }

  }, [location]);


  // 메인 : /
  // 온강의 모든 것: /intro
  // 성공사례:/example
  // 온강이야기: /story
  // 상담신청: /counsel

  return (
    <Routes>
      {/* S:: 온강 메인 페이지 */}
      <Route path="/" element={<Pages.MainPage />} />
      {/* E:: 온강 메인 페이지 */}

      {/* S:: 온강의 모든 것 페이지 */}
      <Route path="/intro/:contents" element={<Pages.IntroPage />} />
      <Route path="/intro/expert/detail/:id" element={<Pages.IntroExertDetailPage />} />
      <Route path="/intro/news/detail/:id" element={<Pages.IntroNewsDetailPage />} />
      {/* E:: 온강의 모든 것 페이지 */}

      {/* S:: 성공사례 */}
      <Route path="/example" element={<Pages.ExamplePage />} />
      <Route path="/example/detail/:id" element={<Pages.ExampleDetailPage />} />
      {/* E:: 성공사례 */}

      {/* S:: 온강 이야기 페이지 */}
      <Route path="/story/:contents" element={<Pages.StoryPage />} />
      <Route path="/story/review/detail/:id" element={<Pages.StoryReviewDetailPage />} />
      <Route path="/story/column/detail/:id" element={<Pages.StoryColumnDetailPage />} />
      {/* E:: 온강 이야기 페이지 */}

      {/* S:: 상담신청 */}
      <Route path="/counsel" element={<Pages.CounselPage />} />
      {/* E:: 상담신청 */}

      {/* S:: 검색 */}
      <Route path="/search" element={<Pages.SearchPage />} />
      {/* E:: 검색 */}

    </Routes>
  );
}
