import styled from "styled-components";

const InputSearch = styled.div`
  & .input_box {
    position: relative;
    width: 100%;
    & input {
      width: 100%;
      height: 50px;
      padding: 0 30px;
      font-size: 16px;
      font-weight: 500;
      color: #111;
      background-color: #F5F5F5;
      border-radius: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; 
      &::placeholder {
        color: #888;
      }
      &.bk {
        height: 70px;
        font-size: 18px;
        background-color: #000;
        color: #fff;
        &::placeholder {
          color: #C4C4C4;
        }
        & + button {
          width: 30px; height: 30px;
        }
      }
    }
    & button {
      position: absolute;
      top: 50%; right: 30px;
      width: 24px; height: 24px;
      transform: translateY(-50%);
      & img {width: 100%; height: 100%;}
    }
  }
  & .tag_list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #888;
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    & .input_box {
      & input {
        height: calc(100vw*(50/1520));
        padding: 0 calc(100vw*(30/1520));
        font-size: calc(100vw*(16/1520));
        border-radius: calc(100vw*(50/1520));
        &.bk {
          height: calc(100vw*(70/1520));
          font-size: calc(100vw*(18/1520));
          & + button {
            width: calc(100vw*(30/1520)); height: calc(100vw*(30/1520));
          }
        }
      }
      & button {
        right: calc(100vw*(30/1520));
        width: calc(100vw*(24/1520)); height: calc(100vw*(24/1520));
      }
    }
    & .tag_list {
      gap: calc(100vw*(30/1520));
      margin-top: calc(100vw*(20/1520));
      font-size: calc(100vw*(20/1520));
      line-height: calc(100vw*(24/1520));
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    & .input_box {
      & input {
        height: calc(100vw*(54/375));
        padding: 0 calc(100vw*(65/375)) 0 calc(100vw*(20/375));
        font-size: calc(100vw*(16/375));
        border-radius: calc(100vw*(50/375));
        &.bk {
          height: calc(100vw*(55/375));
          font-size: calc(100vw*(16/375));
          & + button {
            width: calc(100vw*(30/375)); height: calc(100vw*(30/375));
          }
        }
        &::placeholder {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; 
        }
      }
      & button {
        right: calc(100vw*(30/375));
        width: calc(100vw*(24/375)); height: calc(100vw*(24/375));
      }
    }
    & .tag_list {
      gap: calc(100vw*(15/375));
      margin-top: calc(100vw*(15/375));
      font-size: calc(100vw*(14/375));
      line-height: calc(100vw*(19/375));
    }
  }
`;

export { InputSearch };

export default { InputSearch };
