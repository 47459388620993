import { useEffect, useState } from "react";
import { InputSearch } from "components/ui/input";
import { SearchLayout } from "../../components";
import Styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import utils from "utils";
import { useBoard } from "hooks";

function SearchList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterInfo, setFilterInfo] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      pageSize: param.recordSize ? param.recordSize : 5,
      parent_category_info_id: param.category_info_id ? param.category_info_id : "",
      category_info_id: "",
      keyword: param.keyword || "",
      type: 2,
      recordSize: 4,
    };
    setFilterInfo({ ...f });
    setSearch(param.keyword ? param.keyword : "");
  }, [location]);

  const boardInfo = useBoard({
    listOption: { ...filterInfo, type: 2 },
  });

  const youtubeInfo = useBoard({
    listOption: { ...filterInfo, type: 3 },
  });

  const columnInfo = useBoard({
    listOption: { ...filterInfo, type: 6 },
  });

  const reviewInfo = useBoard({
    listOption: { ...filterInfo, type: 4 },
  });

  const list = boardInfo.listQuery?.data?.list || [];
  const youtubeList = youtubeInfo.listQuery?.data?.list || [];
  const columnList = columnInfo.listQuery?.data?.list || [];
  const reviewList = reviewInfo.listQuery?.data?.list || [];

  return (
    <Styles.SearchList>
      <div className="search_box">
        <h2 className="tit">
          <span>"검색어"</span>에 대한 검색 결과입니다.
        </h2>
        <InputSearch
          placeholder="사건분야, 변호사, 법률정보 등을 검색해 보세요."
          value={search}
          setValue={setSearch}
          tag={true}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyUp={() => {
            navigate(utils.etc.getQueryParamUrl({ keyword: search, page: 1, disableScrollTop: true }, true));
          }}
          onClick={() => {
            navigate(utils.etc.getQueryParamUrl({ keyword: search, page: 1, disableScrollTop: true }, true));
          }}
        />
        <SearchLayout type={0} title={"성공사례"} search={"/example?keyword=" + search} list={list} />
        <SearchLayout type={1} title={"컬럼"} search={"/story/column?keyword=" + search} list={columnList} />
        <SearchLayout type={2} title={"유튜브"} search={"/story/youtube?keyword=" + search} list={youtubeList} />
        <SearchLayout type={3} title={"의뢰인 후기"} search={"/story/review?keyword=" + search} list={reviewList} />
      </div>
    </Styles.SearchList>
  );
}

export default SearchList;
