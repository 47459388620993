import { IMAGEPATH } from 'service/connector';
import Styles from './styles';

// img
import Img from "resources/image/search/search_img.jpg";
import utils from 'utils';

function Example({list}) {

  return (
    <Styles.Container>
      {
        list.map((item,index)=>{
          return (
            <div className="example" onClick={()=>{ window.location.href = "/example/detail/" + item.id}}>
              <img src={IMAGEPATH + item.thumbnail} className="example_img" alt="" />
              <div className="example_content">
                <span className="tag">{item.keyword}</span>
                <div className="title_box">
                  <p className="example_tit">{item.title}</p>
                  <div className="date_box">
                    <b>종결일</b>
                    <span className="date">{utils.date.getDateType(item.ended_at,'-')}</span>
                    <span className="group">{item.subtitle}</span>
                  </div>
                </div>
                <p className="dec">{item.description_text}</p>
              </div>
            </div>
          )
        })
      }
    </Styles.Container>
  );
}

export default Example;